import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore, compose } from 'redux';
import { default as reduxThunk } from 'redux-thunk';

import { OnBoarding, Application, Authentication, ErrorsToNotifs } from './middlewares';

import regions from 'country-region-data';

import steps from '../components/on-boarding/company/steps.js';

import { default as createRootReducer } from './reducers';

export default (preloadedState = {}) => {

    preloadedState.static = {
        regions: regions
    }

    preloadedState.onboarding = {
        allSteps: steps
    }

    // Create the history object
    const history = createBrowserHistory({ basename: process.env.REACT_APP_BASEPATH });
    // Use redux devtools if available
    const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);
    const allEnhancers = composeEnhancers(
        applyMiddleware(
            reduxThunk
            , routerMiddleware(history)
            , Authentication()
            , Application()
            , OnBoarding()
            , ErrorsToNotifs()
        ));

    return {
        store: createStore(
            createRootReducer(history),
            preloadedState,
            allEnhancers
        ),
        history: history
    };

};
