import _ from 'lodash';

import {
    WORKBENCH_STARTING, APPLICATION_STARTING, 
    GET_ALL_SERVICES_SUCCESS, GET_SERVICE_SUCCESS, GET_ALL_PAGES_SUCCESS,
    GET_COMPANY_BILLING_SUCCESS, GET_ALL_COMPANIES_SUCCESS, CREATE_OWNED_COMPANY_SUCCESS,
    GET_COMPANY_SUCCESS, GET_USER_PROFILE_INFOS_SUCCESS, UPDATE_USER_CONSENTS_SUCCESS,
} from '../actions';

import {
    getAllCompanies, getProfileInfos,
    getAllServices, getAllPages, 
    isGetAllPagesPending, isGetAllServicesPending, areServicesLoading,
    areGetCompaniesPending, areGetCompanyBillingsPending, isGetAllCompaniesPending,
    isGetProfileInfosPending, accessDenied,
    startCompanyOnBoarding, startCurrencyOnBoarding, startConsentsOnBoarding, 
    flagApplicationHasStarted, flagWorkbenchHasStarted,
} from '../actions';

/**
 * Workbench is started when it is 'starting' and every companies are loaded.
 * @param {*} state 
 * @param {*} dispatch 
 */
function checkWorkbenchHasStarted(state, user) {
    return state.application.workbench === 'starting'
        && !isGetAllCompaniesPending(state) && !areGetCompaniesPending(state)
        && !areGetCompanyBillingsPending(state) && !isGetProfileInfosPending(state, user);
}

function checkApplicationHasStarted(state) {
    return state.application.main === 'starting'
        && !isGetAllPagesPending(state) && !isGetAllServicesPending(state)
        && !areServicesLoading(state);
}

function middlewareContext() {

    return store => next => action => {

        const nextAction = next(action);

        const { dispatch } = store;
        const state = store.getState();
        const user = state.users.authenticated;

        const { type, payload, meta } = action;

        switch (type) {

            // When the application starts, we load services and plans
            case APPLICATION_STARTING:
                dispatch(getAllServices());
                dispatch(getAllPages());
                break;

            case GET_ALL_SERVICES_SUCCESS:
            case GET_SERVICE_SUCCESS:
            case GET_ALL_PAGES_SUCCESS:
                if (checkApplicationHasStarted(state)) {
                    dispatch(flagApplicationHasStarted());
                }
                break;

            // If the user is on the workbench, we check for its company and personal infos
            case WORKBENCH_STARTING:
                if (user.accessToken !== null) {
                    dispatch(getProfileInfos(user));
                    dispatch(getAllCompanies(user));
                } else {
                    dispatch(accessDenied());
                }
                break;

            // Once the company is acquired, we load its infos and check for payments
            case GET_ALL_COMPANIES_SUCCESS:
                // If no companies is found then we start company and payment onboarding.
                if (payload.length === 0) {
                    dispatch(startCompanyOnBoarding());
                    dispatch(startCurrencyOnBoarding());
                }
                break;

            case GET_COMPANY_BILLING_SUCCESS:
                if (checkWorkbenchHasStarted(state, user)) {
                    dispatch(flagWorkbenchHasStarted());
                }
                break;

            case GET_COMPANY_SUCCESS:
            case CREATE_OWNED_COMPANY_SUCCESS:
                if (checkWorkbenchHasStarted(state, user)) {
                    dispatch(flagWorkbenchHasStarted());
                }
                break;

            // Check the user consents on every changes and fetch
            case GET_USER_PROFILE_INFOS_SUCCESS:
                if (checkWorkbenchHasStarted(state, user)) {
                    dispatch(flagWorkbenchHasStarted());
                }
            case UPDATE_USER_CONSENTS_SUCCESS:
                const metadata = payload && payload.user_metadata;
                if (metadata && (!metadata.consents || metadata.consents.length < 2)) {
                    dispatch(startConsentsOnBoarding()); // priority is given to this on-boarding
                }
                break;

        }

        // Pass the action to the next middleware. 
        return nextAction;
    };

}

export default middlewareContext;