import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, MenuItem, FormHelperText, LinearProgress } from '@material-ui/core';

import { Formik, Field, Form } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    checkDeviceIsAvailable, pushError,
    isCheckDeviceAvailablePending, isCheckDeviceAvailableErred, isDeviceAvailable,
} from '../../../../../redux/actions';

const WinidSchema = Yup.object().shape({
    winid: Yup.string()
        .matches(/(WIN|QNX)(-([A-Z]{4,5}))?(-([A-Z0-9]{4})){3,4}/i, 'Not a valid HostID')
        .required('Required'),
});

const styles = theme => ({
    root: {
        marginTop: `${theme.spacing.unit * 3}px`
    },
    explaination: {
        textAlign: 'center',
    },
});

class SelectDevice extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        error: null,
    }

    componentDidUpdate() {
        const { next, available, pushError, isDevicesAvailablePending, isDevicesAvailableErred,
            operationPending, operationFinished, isDisabled } = this.props;

        // Once the device is created, we have to disable the form.
        if (isDevicesAvailablePending && !isDisabled) {
            operationPending();
        }

        if (isDisabled) {
            if (isDevicesAvailablePending === false) {
                operationFinished();
                if (available) {
                    this.setState({ error: null });
                    next();
                } else {
                    this.setState({ error: 'Device is not available.' });
                    pushError('Device is not available');
                }
            } else if (isDevicesAvailableErred) {
                operationFinished();
                this.setState({ error: 'Cannot check device availability' });
            }
        }

    }

    render() {
        const {
            classes,
            operation, winid, // results from previous steps and this setp
            previous, next, save, isDisabled, // Steps mgmt
            devices, user, checkDeviceIsAvailable, } = this.props;

        const devicesList = _.map(devices, '_win_id');
        const showList = operation === 'upgrade';

        return (
            <div className={classes.root}>
                <Formik
                    initialValues={{ winid: winid || '' }}
                    validationSchema={WinidSchema}
                    onSubmit={(values, { setSubmitting, setErrors }) => {

                        // Save the results in any case
                        values.winid = values.winid.toUpperCase();
                        save(values.winid);

                        // If its a creation, we check for the availabilty of the device.
                        if (operation === 'create') {
                            if (devicesList.includes(values.winid)) {
                                next(); // device is available for the user
                            } else {
                                checkDeviceIsAvailable(values.winid, user);
                            }
                        } else {
                            if (devicesList.includes(values.winid)) {
                                next(); // go directly to the next level
                            } else {
                                setErrors({ winid: 'The device is not in the list.' })
                            }
                        }
                        setSubmitting(false);
                    }}

                    render={({ errors }) => {

                        return (
                            <Form>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.explaination}>
                                            {showList ?
                                                'Select an existing device in the list to upgrade its license with this service.'
                                                : 'Enter the hostID of the device you want to create'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {showList ?
                                            <div>
                                                <Field name="winid" component={Select}
                                                    placeholder="Select a device" label="Device" fullWidth>
                                                    ({_.map(devices, (device, winid) => {
                                                        return <MenuItem key={winid} value={winid}>{device.name}</MenuItem>;
                                                    })})
                                                </Field>
                                                {errors.winid && <FormHelperText error>{errors.winid}</FormHelperText>}
                                            </div>
                                            :
                                            <div>
                                                <Field name="winid" component={TextField} placeholder="HostID of the workbench" label="HostID" fullWidth />
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" disabled={isDisabled} onClick={previous} color="primary" fullWidth>
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Button variant="contained" disabled={isDisabled} type='submit' color="primary" fullWidth>
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }} />
            </div >
        )
    }

}

const mapStateToProps = (state, ownProps) => {

    const winid = ownProps.winid || '';
    let device = ownProps.devices[ownProps.winid];    // try to get the choosen device
    let available = isDeviceAvailable(state, winid);
    available = available && available.available;

    return {
        device,
        available,
        isDevicesAvailablePending: isCheckDeviceAvailablePending(state, winid),
        isDevicesAvailableErred: isCheckDeviceAvailableErred(state, winid),
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        pushError,
        checkDeviceIsAvailable,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(SelectDevice));
