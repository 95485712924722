import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Formik } from 'formik';
import * as Yup from 'yup';

import {
    updateCompany, isUpdateCompanyErred, isUpdateCompanyPending,
} from '../../../../redux/actions';

const currencies = [
    { index: 'USD', label: 'US Dollar' },
    { index: 'EUR', label: 'Euro' },
    { index: 'GBP', label: 'Pound Sterling' }];

const CurrencySchema = Yup.object().shape({
    currency: Yup.string().oneOf(currencies.map(c => c.index)).required('Required'),
});

class ChosseCurrencyController extends React.Component {

    static propTypes = {
        render: PropTypes.func.isRequired,
        currency: PropTypes.string.isRequired,
        isUpdatePending: PropTypes.bool.isRequired,
        isUpdateErred: PropTypes.bool.isRequired,
        onComplete: PropTypes.func,
        onError: PropTypes.func,
    }

    state = {
        isDisabled: false,
    }

    componentDidUpdate() {
        const { onComplete, onError, isUpdatePending, isUpdateErred } = this.props;
        const { isDisabled } = this.state;

        // Once the currency is changing then we have to disable the form
        if (isUpdatePending && !isDisabled) {
            this.setState({ isDisabled: true });
        }

        if (isDisabled) {
            if (isUpdatePending === false) {
                this.setState({ isDisabled: false });
                onComplete && onComplete();
            } else if (isUpdateErred) {
                this.setState({ isDisabled: false });
                onError && onError();
            }
        }
    }

    render() {
        const { render, currency, updateCompany, company, user } = this.props;
        const { isDisabled } = this.state;

        return (<Formik
            initialValues={{ currency }}
            enableReinitialize={true}
            validationSchema={CurrencySchema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                updateCompany(company, { payment: { currency: values.currency } }, user);
            }}
            render={props => {
                return React.createElement(render, { currencies, ...props, isSubmitting: isDisabled });
            }} />)
    }
}

const mapStateToProps = (state) => {

    const company = state.companies.byId[state.companies.owned];

    return {
        user: state.users.authenticated,
        company,
        currency: company.payment.currency,
        isUpdatePending: company && isUpdateCompanyPending(state, company),
        isUpdateErred: company && isUpdateCompanyErred(state, company),
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        updateCompany,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(ChosseCurrencyController);
