import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import {
    Card, CardHeader, CardMedia, CardContent, CardActions, CardActionArea,
    List, ListItem, ListItemIcon, ListItemText, Button,
    Typography, Grid, Divider, Icon
} from '@material-ui/core';

import {
    PlusOneRounded as AddIcon,
    Visibility as VisibilityIcon,
} from '@material-ui/icons';

import NewOrderDialog from '../order/new-order-dialog';

import { getAllOrdersForService } from '../../../redux/actions';

import logo from '../../../assets/images/cards/device.jpg';


const styles = theme => ({
    card: {
        maxWidth: 400,
    },

    media: {
        zIndex: 1,
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    header: {
        display: 'block',
        position: 'relative',
    },
    overlay: {
        zIndex: 2,
        display: 'block',
        position: 'absolute',
    },
    overlayBottomLeft: {
        bottom: '0px',
    },
    overlayBottomRight: {
        bottom: '0px',
        right: '0px',
    },
    overlayTypo: {
        color: theme.palette.common.white,
    },

    description: {
        textAlign: 'justify',
    },

    heading: {
        fontWeight: 600,
    },

    emphasisDetails: {
        textAlign: 'center',
        fontWeight: 600,
    },

    actions: {
        display: 'flex',
        justifyContent: 'center',
    },
    buttons: {
        width: '50%',
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    listIcon: {
        marginRight: '0px',
        marginLeft: `${theme.spacing.unit * 2}px`,
    }
});

const currencyMapping = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
}

class ServiceCard extends React.Component {

    state = {
        open: false,
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    constructor (props) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.goToService = this.goToService.bind(this);
    }

    openDialog() {
        this.setState({ open: true });
    }

    closeDialog() {
        const { service, user, getAllOrdersForService } = this.props;
        getAllOrdersForService(service._id, user);
        this.setState({ open: false });
    }

    componentDidMount() {
        const { service, user, getAllOrdersForService } = this.props;
        if (service) {
            getAllOrdersForService(service._id, user);
        }
    }

    goToService() {
        const { service, push, match } = this.props;
        push(`${match.url}/services/${service._id}`);
    }

    generateFeaturesList = (features) => {
        const { classes } = this.props;

        return (<List dense>
            {_.map(features, (feature, index) => (
                <ListItem key={index}>
                    <ListItemIcon className={classes.listIcon}>
                        <Icon
                            className={feature.icon}
                            fontSize="large"
                        />
                    </ListItemIcon>
                    <ListItemText primary={feature.name} secondary={feature.description} />
                </ListItem>
            ))}
        </List>);
    }

    render() {
        const { classes, service, orders, plans } = this.props;

        const minPrice = _.minBy(plans, 'payment.amount') || { payment: { amout: 0, currency: 'EUR', interval: 'month' } };

        return (
            <Card className={classes.card}>
                <CardActionArea onClick={this.goToService}>
                    <div className={classes.header}>
                        <CardMedia
                            className={classes.media}
                            image={service.description.image || logo}
                            title={`Service: ${service.name}`}
                        />
                        <CardHeader
                            classes={{
                                title: classes.overlayTypo,
                                subheader: classes.overlayTypo,
                            }}
                            className={classnames(classes.overlay, classes.overlayBottomLeft)}
                            title={service.description.title || service.name}
                            subheader={service.description.subtitle}
                        />
                        <CardHeader
                            classes={{
                                title: classes.overlayTypo,
                                subheader: classes.overlayTypo,
                            }}
                            className={classnames(classes.overlay, classes.overlayBottomRight)}
                            title={`${minPrice.payment.amount / 100}${currencyMapping[minPrice.payment.currency]}`}
                            subheader={`per ${minPrice.payment.interval}`}
                        />
                    </div>

                    <CardContent>

                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography className={classes.description} component='p' variant='subtitle1' >
                                    {service.description.description}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Typography className={classes.emphasisDetails} component='h6' align='left'>
                                            You have {orders.length} active licenses
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardContent>
                </CardActionArea>

                <CardActions className={classes.actions} disableActionSpacing>
                    <Button className={classes.buttons}
                        onClick={this.openDialog}
                        color='primary'
                        aria-label='Add license'>
                        <AddIcon className={classes.leftIcon} /> Add license
                    </Button>
                    <Button className={classes.buttons}
                        onClick={this.goToService}
                        color='primary'
                        aria-label='See Details'>
                        <VisibilityIcon className={classes.leftIcon} /> See Details
                    </Button>
                </CardActions>

                <NewOrderDialog open={this.state.open} service={service} plans={plans} handleClose={this.closeDialog} />

            </Card >
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    let plans = [];
    let orders = [];
    // Get the service from the list passed as props.
    let service = ownProps.service;

    if (!service) {
        console.error('Service is undefined, error loading Active Pro service');
    } else {
        plans = _.map(service.plans, (plan) => state.plans.byId[plan]);
        plans = _.compact(plans);
        orders = state.orders.byService[service._id];
        orders = _.compact(_.map(orders, order => state.orders.byId[order]));
        orders = _.filter(orders, order => !order.cancelationPending);
    }

    return {
        user: state.users.authenticated,
        service,
        plans,
        orders,
    };
}



const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        getAllOrdersForService,
        push,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withStyles(styles)(ServiceCard)));
