import { combineReducers } from 'redux';
import _ from 'lodash';

import {
    GET_SERVICE_SUCCESS, GET_SERVICE_ERROR, GET_SERVICE_PENDING,
    GET_ALL_SERVICES_SUCCESS, GET_ALL_SERVICES_ERROR, GET_ALL_SERVICES_PENDING,
} from '../actions';

function servicesByName(state = {}, { type, payload }) {

    switch (type) {
        case GET_SERVICE_SUCCESS:
            _.merge(state, { [payload.name]: payload._id });
            return state;
        default:
            return state;
    }

}

function servicesById(state = {}, { type, payload }) {

    switch (type) {
        case GET_SERVICE_SUCCESS:
            payload.plans = _.map(payload.plans, '_id');
            _.merge(state, { [payload._id]: payload });
            return state;
        default:
            return state;
    }

}

function allServices(state = [], { type, payload }) {

    state = _.clone(state);

    switch (type) {
        case GET_ALL_SERVICES_SUCCESS:
            _.each(payload, (service) => {
                !state.includes(service.id) && state.push(service.id);
            });
            return state;
        default:
            return state;
    }
}

function isFetching(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_SERVICE_SUCCESS:
            return _.merge(state, { [payload._id]: false });
        case GET_SERVICE_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case GET_SERVICE_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isFetchingAll(state = false, { type, payload, meta }) {
    switch (type) {
        case GET_ALL_SERVICES_SUCCESS:
            return false;
        case GET_ALL_SERVICES_ERROR:
            return payload.error;
        case GET_ALL_SERVICES_PENDING:
            return meta.timestamp;
        default:
            return state;
    }
}

export default combineReducers({
    byId: servicesById,
    byName: servicesByName,
    allIds: allServices,
    isFetching,
    isFetchingAll,
});
