import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { updateCompany, isUpdateCompanyErred, isUpdateCompanyPending } from '../../../../redux/actions';


const getCountry = (countries, selected) => {
    if (selected && selected >= 0 && selected < countries.length) {
        return countries[selected];
    }
    return undefined;
}

const getCounties = (countries, selected) => {
    const country = getCountry(countries, selected);
    if (country) return country.regions;
    return [];
}

const getCounty = (counties, selected) => {
    if (selected && selected >= 0 && selected < counties.length) {
        return counties[selected];
    }
    return undefined;
}

const CompanySchema = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    address: Yup.object().shape({
        street: Yup.string().required('Required'),
        street_opt: Yup.string(),
        city: Yup.string().required('Required'),
        post_code: Yup.string().required('Required'),
        country: Yup.number().min(0, 'Required'),
        county: Yup.number(),
    })
});

class CompanyEditFormController extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout);
    }

    waitForSave = (setSubmitting) => {
        this.timeout = setTimeout(() => {
            const { isUpdatePending, isUpdateErred } = this.props;

            if (!isUpdatePending || isUpdateErred) {
                setSubmitting(false);
                this.timeout = null;
            } else {
                this.waitForSave(setSubmitting);
            }

        }, 500);
    }

    render() {
        const { render, updateCompany, user, company, countries } = this.props;

        const initialValues = _.cloneDeep(_.pick(company, ['name', 'address']));
        initialValues.address.country = _.findIndex(countries, (country) => country.countryName === company.address.country) || -1;
        const counties = getCounties(countries, initialValues.address.country);
        initialValues.address.county = _.findIndex(counties, (county) => county.name === company.address.county) || -1;

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={CompanySchema}
                onSubmit={(values, { setSubmitting }) => {

                    values = _.cloneDeep(values);
                    // Refactor values 
                    let country = getCountry(countries, values.address.country);
                    let counties = getCounties(countries, values.address.country);
                    let county = getCounty(counties, values.address.county);

                    values.address.country = country ? country.countryName : undefined;
                    values.address.county = county ? county.name : undefined;

                    updateCompany(company, values, user);
                    this.waitForSave(setSubmitting);
                }}

                render={props => {
                    return React.createElement(render, { countries, getCountry, getCounties, getCounty, ...props });
                }} />
        )
    };

}

/**
 * Map the consents of the authenticated user
 */
const mapStateToProps = state => {
    const company = state.companies.byId[state.companies.owned];
    return {
        user: state.users.authenticated,
        company,
        isUpdatePending: company && isUpdateCompanyPending(state, company),
        isUpdateErred: company && isUpdateCompanyErred(state, company),
        countries: state.static.regions,
    }
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        updateCompany,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(CompanyEditFormController);
