import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    AppBar, Avatar, Toolbar, IconButton, Link, Button,
    Menu, MenuItem, Typography, CssBaseline,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

import { push } from 'connected-react-router';
import { startLogin, logout } from '../../../redux/actions';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary[theme.palette.type],
    },
    profile: {
        display: 'inline',
        color: 'inherit',
    },
    title: {
        flexGrow: 1,
        //pointer: cursor
    },
});

class Header extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        location: PropTypes.object.isRequired,
    }

    state = {
        anchorEl: null,
    }

    constructor (props) {
        super(props);

        this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
    }

    handleProfileMenuOpen(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose() {
        this.setState({ anchorEl: null });
    }

    render() {

        const { anchorEl } = this.state;
        const { classes, location, title, profile, logout, push, startLogin } = this.props;

        let name = profile ? (profile.user_metadata.username || profile.nickname) :  '';
        name = name.replace(/^\w/, c => c.toUpperCase());

        const isMenuOpen = Boolean(anchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={() => { this.handleMenuClose(); push('/workbench/profile'); }}>My Profile</MenuItem>
                <MenuItem onClick={() => { this.handleMenuClose(); logout(); }}>Logout</MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position='fixed' className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>

                        <Link variant='h6' color='inherit' noWrap className={classes.title}
                            onClick={() => { push('/') }}>
                            {title}
                        </Link>


                        {profile ?
                            <div>
                                <Typography
                                    className={classes.profile}
                                    component='span'>
                                    Welcome {name}
                                </Typography>
                                <IconButton
                                    aria-owns={'menu-appbar'}
                                    aria-haspopup='true'
                                    onClick={this.handleProfileMenuOpen}
                                    color='inherit'>
                                    <Avatar
                                        src={(profile && profile.picture) || undefined}>
                                        {!(profile && profile.picture) && <AccountCircle />}
                                    </Avatar>
                                </IconButton>
                            </div>
                            :
                            <Button onClick={() => startLogin(location)} color="secondary">Sign up</Button>
                        }


                    </Toolbar>
                </AppBar>
                {renderMenu}
            </div>
        );
    }
}

const mapStateToProps = state => {

    const user = state.users.authenticated;
    return {
        user,
        profile: state.users.byProviderId[user.provider_id]
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        logout,
        startLogin,
        push,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withStyles(styles)(Header)));