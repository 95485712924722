import { combineReducers } from 'redux';
import _ from 'lodash';

import { START_ON_BOARDING, STOP_ON_BOARDING, ON_BOARDING_NAMING_ERROR, SET_CURRENT_ON_BOARDING_STEP } from '../actions';


function current(state = -1, { type, payload }) {

    switch (type) {
        case SET_CURRENT_ON_BOARDING_STEP:
            return payload;
        case ON_BOARDING_NAMING_ERROR:
            return -1; // Set it to null explicitely
        default:
            return state;
    }

}

function allSteps(state = [], { type, payload }) {

    state = _.clone(state);
    let step;
    switch (type) {
        case STOP_ON_BOARDING:
            step = _.findIndex(state, (step) => step.name === payload);
            if(step >= 0) {
                state[step].missing = false;
            }
            return state;
        case START_ON_BOARDING:
            step = _.findIndex(state, (step) => step.name === payload);
            if(step >= 0) {
                state[step].missing = true;
                state[state.length - 1].missing = true; // Always activate the last step
            }
            return state;
        default:
            return state;
    }

}

export default combineReducers({
    current: current,
    allSteps: allSteps,
});
