import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { SnackbarProvider } from 'notistack';
import { StripeProvider } from 'react-stripe-elements';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

import './index.css';
import * as serviceWorker from './serviceWorker';

import theme from './theme';

import PageLayout from './components/layout';
import configureStore from './redux/configureStore'

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `${process.env.NODE_ENV}-${process.env.REACT_APP_VERSION}`,
});

const { store, history } = configureStore(/* provide initial state if any */)

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                <MuiThemeProvider theme={theme}>
                    <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                        <Route render={({ location, history }) => <PageLayout history={history} location={location} />} />
                    </SnackbarProvider >
                </MuiThemeProvider>
            </StripeProvider>
        </ConnectedRouter>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
