import React from 'react';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { Header, Sidebar } from './items';

import Page from '../static/page';

const styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    },
    toolbar: theme.mixins.toolbar,
});

function staticLayout(props) {

    const { classes, match, pages } = props;
    const title = process.env.REACT_APP_TITLE;

    return (
        <div className={classes.root} >
            <Header title={title} />
            <Sidebar />
            <div className={classes.content}>
                <div className={classes.toolbar}></div>
                <Paper className={classes.content}>

                    <Switch>
                        {pages.map((page, index) => <Route key={index}
                            path={`${match.url}${page.parameters.uri}`}
                            render={p => <Page page={page} />}
                        />)}
                    </Switch>

                </Paper>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    pages: _.map(state.static.pages.byId),
});

export default connect(mapStateToProps)(withStyles(styles)(staticLayout));