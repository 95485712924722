
import { combineReducers } from 'redux';
import _ from 'lodash';

import {
    APPLICATION_STARTING, APPLICATION_STARTED, APPLICATION_STOPPING, APPLICATION_STOPPED,
    WORKBENCH_STARTING, WORKBENCH_STARTED, WORKBENCH_STOPPING, WORKBENCH_STOPPED,
} from '../actions';


function main(state = 'stopped', { type, payload, meta }) {

    switch (type) {
        case APPLICATION_STARTING:
            return 'starting';
        case APPLICATION_STARTED:
            return 'started';
        case APPLICATION_STOPPING:
            return 'stopping';
        case APPLICATION_STOPPED:
            return 'stopped';
        default:
            return state;
    }

}

function workbench(state = 'stopped', { type, payload, meta }) {
    switch (type) {
        case WORKBENCH_STARTING:
            return 'starting';
        case WORKBENCH_STARTED:
            return 'started';
        case WORKBENCH_STOPPING:
            return 'stopping';
        case WORKBENCH_STOPPED:
            return 'stopped';
        default:
            return state;
    }
}

export default combineReducers({
    main,
    workbench,
});
