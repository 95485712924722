import _ from 'lodash';

////////////////////////////////////////////////////
//  Action Types
////////////////////////////////////////////////////

export const NOTIF_SHOW = '@notif/SHOW';
export const NOTIF_SHOWN = '@notif/SHOWN';
export const NOTIF_DISMISSED = '@notif/DISMISSED';
export const NOTIF_HIDE = '@notif/HIDE';
export const NOTIF_CLOSE = '@notif/CLOSE';

////////////////////////////////////////////////////
//  Action Creators
////////////////////////////////////////////////////

export function pushSuccess(message, options = {}) {
    return pushNotification({ message, level: 'success' }, options);
}

export function pushInfo(message, options = {}) {
    return pushNotification({ message, level: 'info' }, options);
}

export function pushWarning(message, options = {}) {
    return pushNotification({ message, level: 'default' }, options);
}

export function pushError(message, options = {}) {
    return pushNotification({ message, level: 'error' }, options);
}

/**
 * Used when the workbench is starting. The 'Real' application is running,
 * so we can react to it in the middlewares.
 */
export function pushNotification({ message, level = 'info'},
    { duration = 6000, persist = false, preventDuplicate = true, key = Date.now(), action = null }) {
    return {
        type: NOTIF_SHOW,
        payload: { message, level, key,  duration, persist, action, preventDuplicate  },
        meta: { timestamp: Date.now(), }
    }
}

export function notifShown(notif) {
    return {
        type: NOTIF_SHOWN,
        payload: notif,
        meta: { timestamp: Date.now(), }
    }
}

export function notifDismissed(notif) {
    return {
        type: NOTIF_DISMISSED,
        payload: notif,
        meta: { timestamp: Date.now(), }
    }
}

export function hideNotification(notif) {
    return {
        type: NOTIF_HIDE,
        payload: notif,
        meta: { timestamp: Date.now(), }
    }
}

export function closeNotification(notif) {
    return {
        type: NOTIF_CLOSE,
        payload: notif,
        meta: { timestamp: Date.now(), }
    }
}
