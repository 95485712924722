import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import {
    FormGroup, FormControlLabel, FormHelperText,
    Button, Typography, Grid, Link as MLink,
} from '@material-ui/core';

import { Form, Field } from 'formik';
import { Checkbox } from 'formik-material-ui';

import UserConsentsController from './controller';

const styles = theme => ({
    form: {
        width: '100%',
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
    group: {
        marginBottom: theme.spacing.unit * 2,
    },
    helper: {

    },
});

class UserConsentsDefault extends React.Component {

    render() {
        const { classes } = this.props;

        return (

            <UserConsentsController render={({ errors, isSubmitting }) => (
                <Form className={classes.form}>

                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            <FormGroup className={classes.group}>
                                <Typography variant='subtitle1'>
                                    Would you like to receive periodic updates about the new services available ?
                                    </Typography>
                                {errors.newsletter && <FormHelperText className={classes.helper} error>{errors.newsletter}</FormHelperText>}
                                <FormControlLabel
                                    control={<Field name="newsletter" component={Checkbox} disabled={isSubmitting} />}
                                    label="Receive Newsletters ?" />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={6}>
                            <FormGroup className={classes.group}>
                                <Typography variant='subtitle1'>
                                    You agreed with and have read our <MLink to='/static/privacy-policies' target='_blank' component={Link}>Privacy policies</MLink>, <MLink to='/static/general-terms' target='_blank' component={Link}>General Terms</MLink>, and <MLink to='/static/sales-conditions' target='_blank' component={Link}>Conditions of Sales</MLink>.
                                </Typography>
                                {errors['general-terms'] && <FormHelperText className={classes.helper} error>{errors['general-terms']}</FormHelperText>}
                                <FormControlLabel
                                    control={<Field name="general-terms" component={Checkbox} />}
                                    label="Accept Terms & conditions" />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Button type="submit" color="primary"
                        variant="contained" fullWidth disabled={isSubmitting}>Save</Button>

                </Form>
            )} />
        )
    }
}


export default withStyles(styles)(UserConsentsDefault);