import { combineReducers } from 'redux';
import _ from 'lodash';

import {
    GET_DEVICE_SUCCESS, GET_DEVICE_ERROR, GET_DEVICE_PENDING,
    GET_ALL_DEVICES_SUCCESS, GET_ALL_DEVICES_PENDING, GET_ALL_DEVICES_ERROR,
    CREATE_DEVICE_SUCCESS, CREATE_DEVICE_PENDING, CREATE_DEVICE_ERROR,
    CHECK_DEVICE_AVAILABILITY_SUCCESS, CHECK_DEVICE_AVAILABILITY_PENDING, CHECK_DEVICE_AVAILABILITY_ERROR,
} from '../actions';

function byId(state = {}, { type, payload, meta }) {

    switch (type) {
        case GET_DEVICE_SUCCESS:
        case CREATE_DEVICE_SUCCESS:
            _.merge(state, { [payload._id]: payload });
            return state;
        default:
            return state;
    }

}

function byWinId(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_DEVICE_SUCCESS:
        case CREATE_DEVICE_SUCCESS:
            _.merge(state, { [payload._win_id]: payload._id });
            return state;
        default:
            return state;
    }
}

function allIds(state = [], { type, payload, meta }) {

    state = _.clone(state);

    switch (type) {
        case GET_ALL_DEVICES_SUCCESS:
            _.each(payload, (device) => {
                !state.includes(device.id) && state.push(device.id);
            });
            return state;
        default:
            return state;
    }
}

function isFetching(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_DEVICE_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case GET_DEVICE_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case GET_DEVICE_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isCreating(state = {}, { type, payload, meta }) {
    switch (type) {
        case CREATE_DEVICE_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case CREATE_DEVICE_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case CREATE_DEVICE_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isAvailable(state = {}, { type, payload, meta }) {
    switch (type) {
        case CHECK_DEVICE_AVAILABILITY_SUCCESS:
            return _.merge(state, { [meta.target]: payload });
        default:
            return state;
    }
}

function isChecking(state = {}, { type, payload, meta }) {
    switch (type) {
        case CHECK_DEVICE_AVAILABILITY_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case CHECK_DEVICE_AVAILABILITY_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case CHECK_DEVICE_AVAILABILITY_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isFetchingAll(state = false, { type, payload, meta }) {
    switch (type) {
        case GET_ALL_DEVICES_SUCCESS:
            return false;
        case GET_ALL_DEVICES_ERROR:
            return payload.error;
        case GET_ALL_DEVICES_PENDING:
            return meta.timestamp;
        default:
            return state;
    }
}

export default combineReducers({
    byId,
    byWinId,
    allIds,
    isFetching,
    isCreating,
    isChecking,
    isAvailable,
    isFetchingAll,
});
