import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, } from '@material-ui/core';

import { Formik, Form } from 'formik';


const styles = theme => ({
    root: {
        marginTop: `${theme.spacing.unit * 3}px`
    },
    explaination: {
        textAlign: 'center',
    },
});

const currencyMapping = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
}

const periodMapping = {
    'lifetime': 'once',
    'day': 'daily',
    'month': 'monthly',
    'year': 'yearly',
}

class RenewSubscriptionReview extends React.Component {

    componentDidUpdate() {
        const { next, available, isOrderCreatePending, isOrderCreateErred,
            operationPending, operationFinished, isDisabled } = this.props;

        // Once the device is created, we have to disable the form.
        if (isOrderCreatePending && !isDisabled) {
            operationPending();
        }

        if (isDisabled) {
            if (isOrderCreatePending === false) {
                operationFinished();
                if (available) {
                    this.setState({ error: null });
                    next();
                } else {
                    this.setState({ error: 'Device is not available.' })
                }
            } else if (isOrderCreateErred) {
                operationFinished();
                this.setState({ error: 'Cannot check device availability' });
            }
        }
    }

    render() {
        const { classes, previous, save, service, user,
            plans, cancelOrder, device, plan, order} = this.props;

        const currentPlan = _.find(plans, (plan) => plan._id === order.plan_model);

        const current = {
            amount: currentPlan.payment.amount / 100,
            currency: currencyMapping[currentPlan.payment.currency],
            interval: periodMapping[currentPlan.payment.interval],
        }
        const coming = {
            amount: plan.payment.amount / 100,
            currency: currencyMapping[plan.payment.currency],
            interval: periodMapping[plan.payment.interval],
        }

        return (
            <div className={classes.root}>
                <Formik
                    initialValues={{}}
                    onSubmit={(values, { setSubmitting }) => {
                        save(true);
                        cancelOrder(order._id, user);
                        this.waitForOrderCancelation(order._id, setSubmitting);
                    }}

                    render={({ errors, isSubmitting }) => {

                        return (
                            <Form>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.explaination}>
                                            The Workplace {device._win_id} is already subscribed to the service.
                                        </Typography>
                                        <Typography className={classes.explaination}>
                                            Do you want to change the subscription from {current.amount}{current.currency} paid {current.interval}{' '}
                                            to {coming.amount}{coming.currency} paid {coming.interval} ?
                                        </Typography>
                                        <Typography className={classes.explaination}>
                                            The current subscription will be canceled. No refund is possible for the running period.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" disabled={isSubmitting} onClick={previous} color="primary" fullWidth>
                                            No
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="outlined" disabled={isSubmitting} type='submit' color="secondary" fullWidth>
                                            Yes, change it !
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }} />
            </div >
        )
    }

}

export default withStyles(styles)(RenewSubscriptionReview);