import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, } from '@material-ui/core';

import { Formik, Form } from 'formik';
import { createDevice, flagWorkbenchStarts } from '../../../../../redux/actions';


const styles = theme => ({
    root: {
        marginTop: `${theme.spacing.unit * 3}px`
    },
    explaination: {
        textAlign: 'center',
    },
});

const currencyMapping = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
}

const periodMapping = {
    'lifetime': 'once',
    'day': 'daily',
    'month': 'monthly',
    'year': 'yearly',
}

class DefaultReview extends React.Component {

    state = {
        creating: false,
        error: null,
    }

    checkDeviceCreation() {
        const { isCreateDevicePending, isCreateDeviceErred, createOrder,
            operationPending, operationFinished, isDisabled, device, plan, user } = this.props;

        // Once the device is created, we have to disable the form.
        if (isCreateDevicePending && !isDisabled) {
            operationPending();
        }

        if (isDisabled) {
            if (isCreateDevicePending === false) {
                if (device._id) {
                    createOrder(device._id, plan._id, user);
                    this.setState({ creating: false });
                }
            } else if (isCreateDeviceErred) {
                operationFinished();
                this.setState({ error: 'Cannot create the device in our database' });
            }
        }
    }

    checkOrderCreation() {
        const { next, isCreateOrderPending, isCreateOrderErred,
            operationPending, operationFinished, isDisabled } = this.props;

        // Once the device is created, we have to disable the form.
        if (isCreateOrderPending && !isDisabled) {
            operationPending();
        }

        if (isDisabled) {
            if (isCreateOrderPending === false) {
                this.setState({ error: null });
                operationFinished();
                next();
            } else if (isCreateOrderErred) {
                operationFinished();
                this.setState({ error: 'Order was not created due to an error' });
            }
        }
    }

    componentDidUpdate() {
        const { creating } = this.state;

        if (creating) {
            this.checkDeviceCreation();
        } else {
            this.checkOrderCreation();
        }

    }

    render() {
        const { classes, previous, save, service, user,
            createDevice, createOrder, device, plan, isDisabled } = this.props;

        const amount = plan.payment.amount / 100;
        const currency = currencyMapping[plan.payment.currency];
        const interval = periodMapping[plan.payment.interval];

        return (
            <div className={classes.root}>
                <Formik
                    initialValues={{}}
                    onSubmit={(values, { setSubmitting }) => {
                        save(true);

                        if (!device._id) {
                            createDevice(device._win_id, 'workbench', user);
                            this.setState({creating: true});
                        } else {
                            createOrder(device._id, plan._id, user);
                        }

                        setSubmitting(false);
                    }}

                    render={({ errors }) => {

                        return (
                            <Form>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.explaination}>
                                            You are subscribing the {service.description.device_name || 'device'} {device._win_id}
                                        </Typography>
                                        <Typography className={classes.explaination}>
                                            to {service.name} for {amount}{currency} paid {interval}.
                                    </Typography>
                                        <Typography className={classes.explaination}>
                                            Do you want to continue ?
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" disabled={isDisabled} onClick={previous} color="primary" fullWidth>
                                            No
                                    </Button>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Button variant="contained" disabled={isDisabled} type='submit' color="primary" fullWidth>
                                            Yes, subscribe !
                                    </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }} />
            </div >
        )
    }
}

export default withStyles(styles)(DefaultReview);