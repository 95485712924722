import _ from 'lodash';
import { combineReducers } from 'redux';
import {
    GET_COMPANY_SUCCESS, GET_COMPANY_PENDING, GET_COMPANY_ERROR,
    UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_PENDING, UPDATE_COMPANY_ERROR,
    ATTACH_SOURCE_PAYMENT_SUCCESS, ATTACH_SOURCE_PAYMENT_PENDING, ATTACH_SOURCE_PAYMENT_ERROR,
    CREATE_OWNED_COMPANY_SUCCESS, CREATE_OWNED_COMPANY_PENDING, CREATE_OWNED_COMPANY_ERROR,
    GET_COMPANY_BILLING_SUCCESS, GET_COMPANY_BILLING_PENDING, GET_COMPANY_BILLING_ERROR,
    GET_ALL_COMPANIES_SUCCESS, GET_ALL_COMPANIES_PENDING, GET_ALL_COMPANIES_ERROR,
} from '../actions';

function owned(state = null, { type, payload }) {
    switch (type) {
        case GET_ALL_COMPANIES_SUCCESS:
            if (payload[0]) {
                return payload[0].id;
            } else {
                return state;
            }
        case CREATE_OWNED_COMPANY_SUCCESS:
            return payload._id;
        default:
            return state;
    }
}

function byId(state = {}, { type, payload }) {

    switch (type) {
        case GET_COMPANY_SUCCESS:
        case UPDATE_COMPANY_SUCCESS:
        case CREATE_OWNED_COMPANY_SUCCESS:
            state = _.merge(state, { [payload._id]: payload });
            return state;
        default:
            return state;
    }

}

function allIds(state = [], { type, payload }) {

    state = _.clone(state);

    switch (type) {
        case GET_ALL_COMPANIES_SUCCESS:
            _.each(payload, (company) => {
                !state.includes(company.id) && state.push(company.id);
            });
            return state;
        case GET_COMPANY_SUCCESS:
        case CREATE_OWNED_COMPANY_SUCCESS:
            !state.includes(payload._id) && state.push(payload._id);
            return state;
        default:
            return state;
    }
}

function billingByCompany(state = {}, { type, payload, meta }) {
    switch (type) {
        case ATTACH_SOURCE_PAYMENT_SUCCESS:
        case GET_COMPANY_BILLING_SUCCESS:
            state = _.merge(state, { [meta.target]: payload });
            return state;
        default:
            return state;
    }
}

function isAttachingSource(state = {}, { type, payload, meta }) {
    switch (type) {
        case ATTACH_SOURCE_PAYMENT_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case ATTACH_SOURCE_PAYMENT_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case ATTACH_SOURCE_PAYMENT_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isFetching(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_COMPANY_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case GET_COMPANY_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case GET_COMPANY_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isFetchingBilling(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_COMPANY_BILLING_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case GET_COMPANY_BILLING_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case GET_COMPANY_BILLING_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isUpdating(state = {}, { type, payload, meta }) {
    switch (type) {
        case UPDATE_COMPANY_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case UPDATE_COMPANY_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case UPDATE_COMPANY_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isCreating(state = false, { type, payload, meta }) {
    switch (type) {
        case CREATE_OWNED_COMPANY_SUCCESS:
            return false;
        case CREATE_OWNED_COMPANY_ERROR:
            return payload.error;
        case CREATE_OWNED_COMPANY_PENDING:
            return meta.timestamp;
        default:
            return state;
    }
}

function isFetchingAll(state = false, { type, payload, meta }) {
    switch (type) {
        case GET_ALL_COMPANIES_SUCCESS:
            return false;
        case GET_ALL_COMPANIES_ERROR:
            return payload.error;
        case GET_ALL_COMPANIES_PENDING:
            return meta.timestamp;
        default:
            return state;
    }
}

export default combineReducers({
    owned,
    byId,
    allIds,
    billingByCompany,
    isFetching,
    isUpdating,
    isCreating,
    isFetchingAll,
    isFetchingBilling,
    isAttachingSource,
});
