import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem, FormHelperText } from '@material-ui/core';

import { Field, Form } from 'formik';
import { TextField, Select } from 'formik-material-ui';

import ChooseCurrencyFormController from './controller';

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    form: {
        width: '100%',
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
});

class ChooseCurrencyForm extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <ChooseCurrencyFormController
                render={({ values, errors, isSubmitting, currencies }) => {

                    return (
                        <Form className={classes.form}>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Field name="currency" component={Select}
                                        placeholder="Currency" label="Currency" fullWidth>
                                        ({currencies.map(({index, label}) => {
                                            return <MenuItem key={index} value={index}>{label}</MenuItem>;
                                        })})
                                    </Field>
                                    {errors.currency && <FormHelperText error>{errors.currency}</FormHelperText>}
                                </Grid>

                                <Grid item xs={4}>
                                    <Button className={classes.button} type="reset" variant="outlined"
                                        disabled={isSubmitting} fullWidth>Cancel</Button>
                                </Grid>
                                <Grid item xs={8}>
                                    <Button className={classes.button} type="submit" variant="contained"
                                        disabled={isSubmitting} color="primary" fullWidth>Save</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }} />
        )
    };

}

export default withStyles(styles)(ChooseCurrencyForm);
