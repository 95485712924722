import axios from 'axios';
import _ from 'lodash';

import { catchNetworkError, catchUnknownServerError } from './server';

////////////////////////////////////////////////////
//  Actions types
////////////////////////////////////////////////////

export const UPDATE_USER_CONSENTS_SUCCESS = '@users/UPDATE_CONSENTS_SUCCESS';
export const UPDATE_USER_CONSENTS_PENDING = '@users/UPDATE_CONSENTS_PENDING';
export const UPDATE_USER_CONSENTS_ERROR = '@users/UPDATE_CONSENTS_ERROR';

export const GET_USER_PROFILE_INFOS_SUCCESS = '@users/GET_PROFILE_SUCCESS';
export const GET_USER_PROFILE_INFOS_PENDING = '@users/GET_PROFILE_PENDING';
export const GET_USER_PROFILE_INFOS_ERROR = '@users/GET_PROFILE_ERROR';

export const UPDATE_USER_PROFILE_SUCCESS = '@users/UPDATE_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_PENDING = '@users/UPDATE_PROFILE_PENDING';
export const UPDATE_USER_PROFILE_ERROR = '@users/UPDATE_PROFILE_ERROR';

////////////////////////////////////////////////////
//  Actions creators
////////////////////////////////////////////////////

export function getProfileInfosSuccess(user) {
    return {
        type: GET_USER_PROFILE_INFOS_SUCCESS,
        payload: user,
        meta: { timestamp: Date.now(), target: user.provider_id },
    }
}

export function getProfileInfosError(user, e, hidden = false) {
    return {
        type: GET_USER_PROFILE_INFOS_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: user.provider_id },
    }
}

export function getProfileInfosPending(user) {
    return {
        type: GET_USER_PROFILE_INFOS_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: user.provider_id },
    }
}

/**
 * Try to fetch the company that the user own.
 */
export function getProfileInfos(user) {

    return (dispatch, getState) => {

        const pending = isGetProfileInfosPending(getState(), user);

        if (!pending) {
            dispatch(getProfileInfosPending(user));

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: '/users/me',
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {
                const user = res.data.data;
                dispatch(getProfileInfosSuccess(user));
            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getProfileInfosError(user, e, flag));
            });
        }
    }

}

export function updateUserConsentsSuccess(user) {
    return {
        type: UPDATE_USER_CONSENTS_SUCCESS,
        payload: user,
        meta: { timestamp: Date.now(), target: user.provider_id },
    }
}

export function updateUserConsentsError(user, e, hidden = false) {
    return {
        type: UPDATE_USER_CONSENTS_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: user.provider_id },
    }
}

export function updateUserConsentsPending(user) {
    return {
        type: UPDATE_USER_CONSENTS_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: user.provider_id },
    }
}

/**
 * Try to fetch the company that the user own.
 */
export function updateUserConsents(user, consents) {

    return (dispatch, getState) => {

        const pending = isUpdateUserConsentsPending(getState(), user);

        if (!pending) {

            dispatch(updateUserConsentsPending(user))

            axios({
                method: 'post',
                baseURL: process.env.REACT_APP_API_HOST,
                url: '/users/me/consents',
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                },
                data: {
                    consents: _.map(consents, (consent, key) => ({
                        name: key,
                        accepted: consent,
                        recorded_at: Date.now()
                    }))
                }
            }).then(res => {
                const user = res.data.data;
                dispatch(updateUserConsentsSuccess(user));
            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(updateUserConsentsError(user, e, flag));
            });
        }

    }

}

export function updateUserProfileSuccess(user) {
    return {
        type: UPDATE_USER_PROFILE_SUCCESS,
        payload: user,
        meta: { timestamp: Date.now(), target: user.provider_id },
    }
}

export function updateUserProfileError(user, e, hidden = false) {
    return {
        type: UPDATE_USER_PROFILE_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: user.provider_id },
    }
}

export function updateUserProfilePending(user) {
    return {
        type: UPDATE_USER_PROFILE_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: user.provider_id },
    }
}

/**
 * Try to fetch the company that the user own.
 */
export function updateUserProfile(user, { name, email }) {

    return (dispatch, getState) => {

        const pending = isUpdateUserProfilePending(getState(), user);

        if (!pending) {
            dispatch(updateUserProfilePending(user))

            axios({
                method: 'post',
                baseURL: process.env.REACT_APP_API_HOST,
                url: '/users/me/profile',
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                },
                data: {
                    name,
                    email,
                }
            }).then(res => {
                const user = res.data.data;
                dispatch(updateUserProfileSuccess(user));
            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(updateUserProfileError(user, e, flag));
            });
        }
    }

}

////////////////////////////////////////////////////
//  Status check
////////////////////////////////////////////////////

export function isGetProfileInfosPending(state, user) {
    return !!state.users.isFetchingProfile[user.provider_id];
}

export function isUpdateUserConsentsPending(state, user) {
    return !!state.users.isUpdatingConsents[user.provider_id];
}

export function isUpdateUserProfilePending(state, user) {
    return !!state.users.isUpdatingProfile[user.provider_id];
}

export function isGetProfileInfosErred(state, user) {
    const status = state.users.isFetchingProfile[user.provider_id];
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}

export function isUpdateUserConsentsErred(state, user) {
    const status = state.users.isUpdatingConsents[user.provider_id];
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}

export function isUpdateUserProfileErred(state, user) {
    const status = state.users.isUpdatingProfile[user.provider_id];
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}