import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { Switch, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
    Grid,
} from '@material-ui/core';

import { CompanyHeader } from '../company';
import ServiceDetails from './service-details';
import ServiceCard from './service-card';

import { getAllServices } from '../../../redux/actions';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    cards: {
        padding: theme.spacing.unit * 2,
        color: theme.palette.text.primary,
    },
});

class ServicesOverview extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    componentWillMount() {
        const { services, user, getAllServices } = this.props;
        if (services.length === 0) {
            getAllServices(user);
        }
    }

    generateServiceCard = (services) => {
        const { classes } = this.props;
        return _.map(services, (service, index) => (
            <Grid key={index} className={classes.cards} item xs={12} md={6}>
                <ServiceCard service={service} />
            </Grid>)
        )
    }

    render() {
        const { classes, services, match } = this.props;
        return (
            <Switch>
                <Route path={`${match.url}/services/:id`} component={ServiceDetails} />
                <Route render={() => (
                    <div>
                        <CompanyHeader />
                        <Grid className={classes.root} container spacing={24}>
                            {this.generateServiceCard(services)}
                        </Grid>
                    </div>)
                } />
            </Switch>
        );
    }
}

const mapStateToProps = state => {

    const services = _.compact(_.map(state.services.allIds, (id) => state.services.byId[id]));

    return {
        user: state.users.authenticated,
        services,
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        getAllServices,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(ServicesOverview));
