import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogContent, DialogTitle,
    IconButton, LinearProgress,
} from '@material-ui/core';

import {
    Close as CloseIcon
} from '@material-ui/icons';

import NewOrderForm from './form';

const styles = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
});

class NewOrderDialog extends React.Component {

    state = {
        isSubmitting: false,
    }

    static propTypes = {
        handleClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
    }

    constructor(props) {
        super(props);

        this.setSubmitting = this.setSubmitting.bind(this);
    }

    setSubmitting(bool) {
        this.setState({ isSubmitting: bool });
    }

    render() {

        const { classes, service, plans, open, handleClose, deviceName, } = this.props;
        const { isSubmitting } = this.state;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby={`new-${service.name}`}
            >
                {isSubmitting && <LinearProgress />}
                <DialogTitle id={`new-${service.name}`}>Subscribe to {service.description.title || service.name}</DialogTitle>
                <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    {open && <NewOrderForm
                        service={service}
                        plans={plans}
                        onClose={handleClose}
                        setSubmitting={this.setSubmitting}
                        deviceName={deviceName}
                    />}
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(NewOrderDialog);