import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, MenuItem, FormHelperText } from '@material-ui/core';

import { Formik, Field, Form } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';

import { updateCompany, isUpdateCompanyErred, isUpdateCompanyPending } from '../../../redux/actions';

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    form: {
        width: '100%',
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
});


const getCountry = (countries, selected) => {
    if (selected && selected >= 0 && selected < countries.length) {
        return countries[selected];
    }
    return undefined;
}

const getCounties = (countries, selected) => {
    const country = getCountry(countries, selected);
    if (country) return country.regions;
    return [];
}

const getCounty = (counties, selected) => {
    if (selected && selected >= 0 && selected < counties.length) {
        return counties[selected];
    }
    return undefined;
}

const CompanySchema = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    address: Yup.object().shape({
        street: Yup.string().required('Required'),
        street_opt: Yup.string(),
        city: Yup.string().required('Required'),
        post_code: Yup.string().required('Required'),
        country: Yup.number().min(0, 'Required'),
        county: Yup.number(),
    })
});

class CompanyEditForm extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout);
    }

    waitForSave = (setSubmitting) => {
        this.timeout = setTimeout(() => {
            const { isUpdatePending, isUpdateErred } = this.props;

            if (!isUpdatePending || isUpdateErred) {
                setSubmitting(false);
                this.timeout = null;
            } else {
                this.waitForSave(setSubmitting);
            }

        }, 500);
    }

    render() {
        const { classes, updateCompany, user, company, countries } = this.props;

        const initialValues = _.cloneDeep(_.pick(company, ['name', 'address']));
        initialValues.address.country = _.findIndex(countries, (country) => country.countryName === company.address.country) || -1;
        const counties = getCounties(countries, initialValues.address.country);
        initialValues.address.county = _.findIndex(counties, (county) => county.name === company.address.county) || -1;

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={CompanySchema}
                onSubmit={(values, { setSubmitting }) => {

                    values = _.cloneDeep(values);
                    // Refactor values 
                    let country = getCountry(countries, values.address.country);
                    let counties = getCounties(countries, values.address.country);
                    let county = getCounty(counties, values.address.county);

                    values.address.country = country ? country.countryName : undefined;
                    values.address.county = county ? county.name : undefined;
                    values._id = company._id;

                    updateCompany(values, user);
                    this.waitForSave(setSubmitting);
                }}

                render={({ values, errors, isSubmitting }) => {

                    const counties = getCounties(countries, values.address.country);

                    return (
                        <Form className={classes.form}>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Field name="name" component={TextField} placeholder="Name of the company" label="Name" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field name="address.street" component={TextField} placeholder="Street" label="Street" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field name="address.street_opt" component={TextField} placeholder="Street 2nd line" label="Street 2nd line" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field name="address.city" component={TextField} placeholder="City" label="City" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field name="address.post_code" component={TextField} placeholder="Post/Zip Code" label="Post/Zip Code" fullWidth />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field name="address.country" component={Select}
                                        placeholder="Country" label="Country" fullWidth>
                                        ({countries.map((country, index) => {
                                            return <MenuItem key={index} value={index}>{country.countryName}</MenuItem>;
                                        })})
                                    </Field>
                                    {errors.address && errors.address.country && <FormHelperText error>{errors.address.country}</FormHelperText>}
                                </Grid>

                                <Grid item xs={6}>
                                    <Field name="address.county" component={Select} placeholder="County" label="County" fullWidth>
                                        ({counties.map((county, index) => {
                                            return <MenuItem key={index} value={index}>{county.name}</MenuItem>;
                                        })})
                                    </Field>
                                    {errors.address && errors.address.county && <FormHelperText error>{errors.address.county}</FormHelperText>}
                                </Grid>

                                <Grid item xs={12}>
                                    <Button className={classes.button} type="submit" variant="contained"
                                        disabled={isSubmitting} color="primary" fullWidth>Save</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }} />
        )
    };

}

/**
 * Map the consents of the authenticated user
 */
const mapStateToProps = state => {
    const company = state.companies.byId[state.companies.owned];
    return {
        user: state.users.authenticated,
        company,
        isUpdatePending: company && isUpdateCompanyPending(state, company),
        isUpdateErred: company && isUpdateCompanyErred(state, company),
        countries: state.static.regions,
    }
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        updateCompany,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(CompanyEditForm));
