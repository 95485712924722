import _ from 'lodash';

import {
    ATTACH_SOURCE_PAYMENT_ERROR, STRIPE_SOURCE_ERROR, GET_COMPANY_BILLING_ERROR,
    CREATE_OWNED_COMPANY_ERROR, GET_ALL_COMPANIES_ERROR, GET_COMPANY_ERROR, UPDATE_COMPANY_ERROR,
    CANCEL_ORDER_ERROR, CREATE_ORDER_ERROR, GET_ALL_ORDERS_ERROR, GET_ORDER_ERROR,
    GET_ALL_PLANS_ERROR, GET_PLAN_ERROR,
    GET_ALL_SERVICES_ERROR, GET_SERVICE_ERROR,
    CREATE_DEVICE_ERROR, GET_ALL_DEVICES_ERROR, GET_DEVICE_ERROR,
    GET_LICENSE_ERROR,
    GET_USER_PROFILE_INFOS_ERROR, UPDATE_USER_CONSENTS_ERROR,
    SERVER_NETWORK_ERROR, INTERNAL_SERVER_ERROR,
    CHECK_DEVICE_AVAILABILITY_ERROR, UPDATE_USER_PROFILE_ERROR, GET_ALL_PAGES_ERROR, GET_PAGE_ERROR,
    CONNECTIVITY_LOSS_ERROR,
} from '../actions';


import {
    pushError,
} from '../actions';


const mapping = {
    [ATTACH_SOURCE_PAYMENT_ERROR]: { message: 'Cannot attach credit card to your account' },
    [STRIPE_SOURCE_ERROR]: { message: 'Our provider cannot process your card information' },
    [GET_COMPANY_BILLING_ERROR]: { message: 'Cannot load company' },
    [CREATE_OWNED_COMPANY_ERROR]: { message: 'Cannot create the company' },
    [GET_ALL_COMPANIES_ERROR]: { message: 'Cannot load your companies' },
    [GET_COMPANY_ERROR]: { message: 'Cannot load company' },
    [UPDATE_COMPANY_ERROR]: { message: 'Cannot update your company' },
    [CANCEL_ORDER_ERROR]: { message: 'Cannot cancel your order' },
    [CREATE_ORDER_ERROR]: { message: 'Cannot create the order' },
    [GET_ALL_ORDERS_ERROR]: { message: 'Cannot load your orders' },
    [GET_ORDER_ERROR]: { message: 'Cannot load order' },
    [GET_ALL_PLANS_ERROR]: { message: 'Cannot load the pricing plans' },
    [GET_PLAN_ERROR]: { message: 'Cannot load the pricing plans' },
    [GET_ALL_SERVICES_ERROR]: { message: 'Cannot load the services' },
    [GET_SERVICE_ERROR]: { message: 'Cannot load the services' },
    [CREATE_DEVICE_ERROR]: { message: 'Cannot create the device' },
    [GET_ALL_DEVICES_ERROR]: { message: 'Cannot load your devices' },
    [GET_DEVICE_ERROR]: { message: 'Cannot load the device' },
    [GET_LICENSE_ERROR]: { message: 'Cannot load the license' },
    [GET_USER_PROFILE_INFOS_ERROR]: { message: 'Cannot load your profile information' },
    [CHECK_DEVICE_AVAILABILITY_ERROR]: { message: 'Cannot check if device is available' },
    [UPDATE_USER_PROFILE_ERROR]: { message: 'Cannot update your profile' },
    [GET_ALL_PAGES_ERROR]: { message: 'Cannot load static pages' },
    [GET_PAGE_ERROR]: { message: 'Cannot load static pages' },
    [UPDATE_USER_CONSENTS_ERROR]: { message: 'Cannot change your profile settings' },
    [SERVER_NETWORK_ERROR]: { message: 'Cannot connect to Active Platform', persist: true, action: 'Got it' },
    [CONNECTIVITY_LOSS_ERROR]: { message: 'Your are not connected to internet', persist: true, action: 'Got it' },
    [INTERNAL_SERVER_ERROR]: { message: 'We encoutered a problem', details: 'Please contact the support if the problem persists' },
    default: { message: 'An unhandled error occured', details: 'Please contact the support if the problem persists' },
}


function middlewareContext() {

    return store => next => action => {

        const nextAction = next(action);

        const { dispatch } = store;
        const { type, payload, meta } = action;

        if (type.endsWith('ERROR')) {
            // Show a notification
            if (!payload.hidden) {
                const { message, persist = false, action = null }
                    = mapping[type] || mapping.default;
                const options = {
                    persist,
                    action,
                };
                dispatch(pushError(message, options));
            }
            // log the error
            console.error(`${type} [${meta.timestamp}]`, payload.error && (payload.error.message || payload.error));
        }

        // Pass the action to the next middleware. 
        return nextAction;
    };

}

export default middlewareContext;