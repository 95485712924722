import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

import { replace } from 'connected-react-router';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CircularProgress, Typography, Button, Grid } from '@material-ui/core';

const styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        margin: 'auto',
    },
    spaced: {
        marginTop: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit * 2,
    },
});


class NoMatch extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    state = {
        loading: false,
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        if (this.timemout) clearTimeout(this.timeout);
    }

    resetLoading = () => {
        this.timeout = setTimeout(() => {
            this.setState({ loading: false });
            this.timeout = null;
        }, 1000);
    }

    render() {
        const { classes, replace } = this.props;
        const { loading } = this.state;

        return (
            <div className={classes.centered}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h5' component='h3'>404 ...</Typography>
                        <Typography>The page you want to access doesn't exist</Typography>
                    </Grid>
                    <Grid className={classes.spaced} item xs={12}>
                        {loading ?
                            <CircularProgress />
                            :
                            <Button className={classes.button} variant='outlined' onClick={() => {
                                replace('/');
                            }}>Go Home</Button>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.users.authenticated,
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        replace,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(NoMatch));