import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import {
    Grid, Button, MenuItem, FormHelperText, Typography,
    ExpansionPanelDetails, ExpansionPanelActions, Divider,
} from '@material-ui/core';

import { Field, Form } from 'formik';
import { TextField, Select } from 'formik-material-ui';

import ChooseCurrencyFormController from './controller';

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    form: {
        width: '100%',
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
});

class ChooseCurrencyExpansionForm extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <ChooseCurrencyFormController
                render={({ values, errors, isSubmitting, currencies }) => {
                    return (
                        <Form className={classes.form}>
                            <ExpansionPanelDetails>
                                <Grid container spacing={24}>
                                    <Grid item xs={7}>
                                        <Typography variant='h6' component='h4'>
                                            Choose your prefered currency to process the payments
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Field name="currency" component={Select}
                                            placeholder="Currency" label="Currency" fullWidth>
                                            ({currencies.map(({ label, index }) => {
                                                return <MenuItem key={index} value={index}>{label}</MenuItem>;
                                            })})
                                    </Field>
                                        {errors.currency && <FormHelperText error>{errors.currency}</FormHelperText>}
                                    </Grid>

                                </Grid>
                            </ExpansionPanelDetails>
                            <Divider />
                            <ExpansionPanelActions>
                                <Button type="reset" size="small" disabled={isSubmitting}>Cancel</Button>
                                <Button type="submit" size="small" color="primary" disabled={isSubmitting}>
                                    Save
                                </Button>
                            </ExpansionPanelActions>

                        </Form>
                    )
                }} />
        )
    };

}

export default withStyles(styles)(ChooseCurrencyExpansionForm);
