import axios from 'axios';
import _ from 'lodash';

import { catchNetworkError, catchUnknownServerError } from './server';

////////////////////////////////////////////////////
//  Action Types
////////////////////////////////////////////////////

export const GET_ALL_PLANS_SUCCESS = '@plans/GET_ALL_SUCCESS';
export const GET_ALL_PLANS_ERROR = '@plans/GET_ALL_ERROR';
export const GET_ALL_PLANS_PENDING = '@plans/GET_ALL_PENDING';

export const GET_PLAN_SUCCESS = '@plans/GET_ONE_SUCCESS';
export const GET_PLAN_ERROR = '@plans/GET_ONE_ERROR';
export const GET_PLAN_PENDING = '@plans/GET_ONE_PENDING';

////////////////////////////////////////////////////
//  Action Creators
////////////////////////////////////////////////////

export function getAllPlansByServiceSuccess(service, plans) {
    return {
        type: GET_ALL_PLANS_SUCCESS,
        payload: plans,
        meta: { timestamp: Date.now(), target: service },
    }
}

export function getAllPlansByServiceError(service, e, hidden = false) {
    return {
        type: GET_ALL_PLANS_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: service },
    }
}

export function getAllPlansByServicePending(service) {
    return {
        type: GET_ALL_PLANS_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: service },
    }
}

export function getPlanSuccess(plan) {
    return {
        type: GET_PLAN_SUCCESS,
        payload: plan,
        meta: { timestamp: Date.now(), target: plan._id },
    }
}

export function getPlanError(id, e, hidden = false) {
    return {
        type: GET_PLAN_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getPlanPending(id) {
    return {
        type: GET_PLAN_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: id },
    }
}

////////////////////////////////////////////////////
//  Status check
////////////////////////////////////////////////////

export function arePlansLoading(state) {
    return _.find(state.plans.isFetching, (isFetching) => {
        return _.isNumber(isFetching); // error or false
    });
}