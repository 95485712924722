import auth0 from 'auth0-js';
import _ from 'lodash';

// Actions types
export const START_LOGIN = '@auth/START_LOGIN_PROCEDURE';

export const LOGIN_USER = '@auth/LOGIN';
export const LOGOUT_USER = '@auth/LOGOUT';

export const SESSION_MISSING = '@auth/SESSION_MISSING';
export const SESSION_EXPIRED = '@auth/SESSION_EXPIRED';

export const LOGIN_HASH_ERROR = '@auth/HASH_ERROR';

export const ACCESS_DENIED = '@auth/ACCESS_DENIED';
export const NEEDS_LOGIN = '@auth/NEEDS_LOGIN';

let client = null;
function clientFactory() {
    if (client === null) {
        client = new auth0.WebAuth({
            domain: process.env.REACT_APP_AUTH_DOMAIN,
            clientID: process.env.REACT_APP_AUTH_CLIENT_ID,
            responseType: 'token id_token',
            scope: 'openid email profile',
            audience: process.env.REACT_APP_AUTH_AUDIENCE
        });
    }

    return client;
}

////////////////////////////////////////////////////
//  ACCESS MGMT
////////////////////////////////////////////////////

export function needsLogin() {
    return {
        type: NEEDS_LOGIN,
        payload: {},
        meta: { timestamp: Date.now() },
    }
}

export function accessDenied(message) {
    return {
        type: ACCESS_DENIED,
        payload: {
            message,
            reason: 'Access denied on a protected ressource',
        }
    }
}

////////////////////////////////////////////////////
//  SESSION MGMT
////////////////////////////////////////////////////

/**
 * 
 * @param {Object} session 
 * @private
 */
function setSession(session) {
    session.expiresAt = Date.now() + (session.expiresIn * 1000);
    localStorage.setItem('auth-session', JSON.stringify(session));
}

/**
 * 
 * @return {Object} the session object 
 * @private
 */
function getSession() {
    return JSON.parse(localStorage.getItem('auth-session'));
}

/**
 *  
 * @private
 */
function removeSession() {
    localStorage.removeItem('auth-session');
}

/**
 * Action creator
 */
function missingSession() {
    return {
        type: SESSION_MISSING,
        payload: {},
    }
}

/**
 * Action creator
 */
function sessionExpired() {
    removeSession();
    return {
        type: SESSION_EXPIRED,
        payload: {},
    }
}

/**
 * Action creator
 * Log from the session, do not fire a redirection
 */
function loginFromSession(session) {
    return {
        type: LOGIN_USER,
        payload: {
            expiresAt: session.expiresAt,
            profile: session.idTokenPayload,
            jwt: session.idToken,
            accessToken: session.accessToken,
        },
        meta: {
            from: 'session'
        }
    }
}

/**
 * Restore the session from the browser localstorage.
 */
export function restoreSession() {
    return dispatch => {
        const session = getSession(); // Get the session from the localstorage
        const now = Date.now();
        // The session exists
        if (session) {
            if (session.expiresAt <= now) {
                // it has expires, then we logout
                dispatch(sessionExpired());
            } else {
                // it is not expired, we're loged in
                dispatch(loginFromSession(session));
            }
        } else {
            dispatch(missingSession());
        }
    }
}

////////////////////////////////////////////////////
//  HASH MGMT
////////////////////////////////////////////////////

function parsingError(err) {
    return {
        type: LOGIN_HASH_ERROR,
        payload: { message: err.message },
        error: err,
    }
}

function loginFromHash(session) {
    return {
        type: LOGIN_USER,
        payload: {
            expiresAt: session.expiresAt,
            profile: session.idTokenPayload,
            jwt: session.idToken,
            accessToken: session.accessToken,
        },
        meta: {
            from: 'hash'
        }
    }
}

export function parseHash(hash) {

    const client = clientFactory();

    return dispatch => {
        // try to parse the location hash if its a redirect
        client.parseHash({ hash }, (err, session) => {
            if (err) {
                console.error('auth0-hash-parse: ', err);
                dispatch(parsingError(err));
            }

            if (session) {
                setSession(session);
                dispatch(loginFromHash(session));
            } else {
                dispatch(parsingError(new Error('No hash to parse')));
            }
        });
    }
}

////////////////////////////////////////////////////
//  LOGIN
////////////////////////////////////////////////////

export function startLogin(location) {
    const client = clientFactory();
    let redirectUri;

    if (location) {
        if (location.search.length === 1) location.search = ''; // Remove if there is only the '?';
        redirectUri = `${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_AUTH_CALLBACK_URL}?redirectTo=${location.pathname}${location.search}`
    } else {
        redirectUri = `${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_AUTH_CALLBACK_URL}`;
    }

    client.authorize({ redirectUri });

    return {
        type: START_LOGIN,
        payload: { redirect: location },
    }
}

////////////////////////////////////////////////////
//  LOGOUT
////////////////////////////////////////////////////

/**
 * Dispatch the LOGOUT_USER 
 * @private
 */
function successLogout() {
    return {
        type: LOGOUT_USER,
    }
}

/**
 * Logout procedure
 */
export function logout() {
    const client = clientFactory();

    removeSession();
    client.logout({
        returnsTo: process.env.REACT_APP_AUTH_LOGOUT_URL,
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    });

    return successLogout();
}
