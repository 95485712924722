import axios from 'axios';
import _ from 'lodash';

import { catchNetworkError, catchUnknownServerError } from './server';
import { getLicenseDetails } from './licenses';

////////////////////////////////////////////////////
//  Action Types
////////////////////////////////////////////////////

export const GET_ALL_DEVICES_PENDING = '@devices/GET_ALL_PENDING';
export const GET_ALL_DEVICES_SUCCESS = '@devices/GET_ALL_SUCCESS';
export const GET_ALL_DEVICES_ERROR = '@devices/GET_ALL_ERROR';

export const GET_DEVICE_PENDING = '@devices/UPDATE_PENDING';
export const GET_DEVICE_SUCCESS = '@devices/UPDATE_SUCCESS';
export const GET_DEVICE_ERROR = '@devices/UPDATE_ERROR';

export const CREATE_DEVICE_PENDING = '@devices/CREATE_PENDING';
export const CREATE_DEVICE_SUCCESS = '@devices/CREATE_SUCCESS';
export const CREATE_DEVICE_ERROR = '@devices/CREATE_ERROR';

export const CHECK_DEVICE_AVAILABILITY_PENDING = '@devices/CHECK_AVAILABILITY_PENDING';
export const CHECK_DEVICE_AVAILABILITY_SUCCESS = '@devices/CHECK_AVAILABILITY_SUCCESS';
export const CHECK_DEVICE_AVAILABILITY_ERROR = '@devices/CHECK_AVAILABILITY_ERROR';

////////////////////////////////////////////////////
//  Action Creators
////////////////////////////////////////////////////

export function getAllDevicesPending() {
    return {
        type: GET_ALL_DEVICES_PENDING,
        payload: {},
        meta: { timestamp: Date.now() },
    }
}

export function getAllDevicesSuccess(devices) {
    return {
        type: GET_ALL_DEVICES_SUCCESS,
        payload: devices,
        meta: { timestamp: Date.now() }
    }
}

export function getAllDevicesError(e, hidden = false) {
    return {
        type: GET_ALL_DEVICES_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now() },
    }
}

export function getDevicePending(id) {
    return {
        type: GET_DEVICE_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getDeviceSuccess(device) {
    return {
        type: GET_DEVICE_SUCCESS,
        payload: device,
        meta: { timestamp: Date.now(), target: device._id },
    }
}

export function getDeviceError(id, e, hidden = false) {
    return {
        type: GET_DEVICE_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: id }
    }
}

export function createDevicePending(winid) {
    return {
        type: CREATE_DEVICE_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: winid },
    }
}

export function createDeviceSuccess(device) {
    return {
        type: CREATE_DEVICE_SUCCESS,
        payload: device,
        meta: { timestamp: Date.now(), target: device._win_id },
    }
}

export function createDeviceError(winid, e, hidden = false) {
    return {
        type: CREATE_DEVICE_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: winid },
    }
}

export function checkDeviceAvailabilityPending(winid) {
    return {
        type: CHECK_DEVICE_AVAILABILITY_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: winid },
    }
}

export function checkDeviceAvailabilitySuccess(availability, winid) {
    return {
        type: CHECK_DEVICE_AVAILABILITY_SUCCESS,
        payload: availability,
        meta: { timestamp: Date.now(), target: winid },
    }
}

export function checkDeviceAvailabilityError(winid, e, hidden = false) {
    return {
        type: CHECK_DEVICE_AVAILABILITY_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: winid },
    }
}



/**
 * Fetch all the services available for the user.
 * Populate the services with their infos automatically.
 */
export function getAllDevices(user) {

    return (dispatch, getState) => {

        const pending = isGetAllDevicesPending(getState());

        if (!pending) {
            dispatch(getAllDevicesPending());

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: '/devices/',
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {

                const devices = res.data.data;
                dispatch(getAllDevicesSuccess(devices));

                _.each(devices, device => {
                    dispatch(getDevice(device.id, user));
                });

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getAllDevicesError(e, flag));
            });
        }
    }

}

export function getDevice(id, user) {
    return (dispatch, getState) => {

        const pending = isGetDevicePending(getState(), id);
        const existing = isDevicePresentById(getState(), id);

        if (!pending && !existing) {

            dispatch(getDevicePending(id));

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/devices/${id}`,
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {

                const device = res.data.data;
                dispatch(getDeviceSuccess(device));

                // Get the license instance if it doesn't exists
                dispatch(getLicenseDetails(device._license, user));

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getDeviceError(id, e, flag));
            });
        }
    }
}

export function createDevice(winid, kind, user) {
    return (dispatch, getState) => {

        const pending = isCreateDevicePending(getState(), winid);
        const existing = isDevicePresentByWinId(getState(), winid);

        if (!pending && !existing) {

            dispatch(createDevicePending(winid));

            axios({
                method: 'put',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/devices/`,
                data: {
                    winid,
                    kind,
                },
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {

                const device = res.data.data;
                dispatch(createDeviceSuccess(device));

                // Get the license instance if it doesn't exists
                dispatch(getLicenseDetails(device._license, user));

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(createDeviceError(winid, e, flag));
            });
        }
    }
}

export function checkDeviceIsAvailable(winid, user) {
    return (dispatch, getState) => {

        const pending = isCheckDeviceAvailablePending(getState(), winid);

        if (!pending) {

            dispatch(checkDeviceAvailabilityPending(winid));

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/devices/available/${winid}`,
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {

                const availability = res.data.data;
                dispatch(checkDeviceAvailabilitySuccess(availability, winid));

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(checkDeviceAvailabilityError(winid, e, flag));
            });
        }
    }
}

////////////////////////////////////////////////////
//  Status check
////////////////////////////////////////////////////

export function isGetDevicePending(state, id) {
    return !!state.devices.isFetching[id];
}

export function isGetDeviceErred(state, id) {
    const status = state.devices.isFetching[id];
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}

export function isDevicePresentById(state, id) {
    return !!state.devices.byId[id];
}

export function isDevicePresentByWinId(state, winid) {
    const id = state.devices.byWinId[winid];
    return id && !!state.devices.byId[id];
}

export function isGetAllDevicesPending(state) {
    return !!state.devices.isFetchingAll;
}

export function isGetAllDevicesErred(state) {
    const status = state.devices.isFetchingAll;
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}

export function isCreateDevicePending(state, winid) {
    return !!state.devices.isCreating[winid];
}

export function isCreateDeviceErred(state, winid) {
    const status = state.devices.isCreating[winid];
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}

export function isCheckDeviceAvailablePending(state, winid) {
    return !!state.devices.isChecking[winid];
}

export function isCheckDeviceAvailableErred(state, winid) {
    const status = state.devices.isChecking[winid];
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}

export function isDeviceAvailable(state, winid) {
    return state.devices.isAvailable[winid];
}

export function areDevicesLoading(state) {
    return _.find(state.devices.isFetching, (isFetching) => {
        return _.isNumber(isFetching); // error or false
    });
}