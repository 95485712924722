import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import ChooseCurrencyForm from '../../../items/billing/choose-currency-form/default-form';

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
});

function currency(props) {

    const { classes } = props;

    return (
        <div>
            <Typography variant='h6' component='h4' align='left'>
                Please register a new credit card in order to use the platform.
                It will be securely stored by Stripe, our integrated payment solution.
                Your card will only be charged when you enable a new paid service.
                The Active platform access is totally free.
            </Typography>
            <div className={classes.actionsContainer}>
                <ChooseCurrencyForm />
            </div>
        </div>
    )
}

export default withStyles(styles)(currency);
