import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import _ from 'lodash';

import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Grid, IconButton, Chip } from '@material-ui/core';
import {
    DeleteForever as UnsubIcon,
    Close as NoIcon,
    Check as YesIcon,
} from '@material-ui/icons';

import ServiceHeader from './service-header';
import OrderCancelDialog from '../order/order-cancel-dialog';

import {
    getServiceDetails, getAllOrdersForService, getAllDevices,
    areDevicesLoading, areLicensesLoading, areOrdersLoading, arePlansLoading,
    cancelOrder,
} from '../../../redux/actions';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    chip: {
        margin: theme.spacing.unit,
    },
});

class ServicesDetails extends React.Component {

    state = {
        needLoading: false,
        open: false,
        device: null,
        order: null,
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    componentWillMount() {
        const { service, user, getServiceDetails, match } = this.props;
        const id = _.get(match, 'params.id');
        if (!service && id) {
            getServiceDetails(id, user); // Get service details with plans
            this.setState({ needLoading: true });
        } else if (service) {
            this.loadOrdersAndServices();
        }
    }

    handleOpen = (order) => {
        this.setState({
            open: true,
            order: order,
            device: order.deviceCopy
        });
    }

    handleClose = () => {
        this.setState({ open: false, device: null, order: null, });
    }

    getColumns = () => {
        return [{
            name: 'Device',
            options: {
                hint: 'The hostID of the device the subscription is paid for',
            }
        }, {
            name: 'Billing interval',
            options: {
                //hint: 'The pricing plan used for this subscription',
            }
        }, {
            name: 'Renewed At',
            options: {
                hint: 'The date when the license file can be renewed',
            }
        }, {
            name: 'Expires At',
            options: {
                hint: 'The date when the license file will effectively expires',
            }
        }, {
            name: 'Status',
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (value) => {
                    const { classes } = this.props;
                    const color = (value === 'failed' && 'secondary')
                        || (value === 'trialing' && 'primary')
                        || 'default';

                    return (
                        <Chip label={value} color={color} className={classnames(classes.chip)} />
                    );
                }
            }
        }, {
            name: 'Generated',
            options: {
                searchable: false,
                hint: 'The license file was generated at least once',
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? <YesIcon /> : <NoIcon />;
                }
            }
        }, {
            name: "Unsubscribe",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const order = this.props.orders[tableMeta.rowIndex];
                    return (
                        <IconButton disabled={!value}
                            color='secondary'
                            onClick={() => {
                                this.handleOpen(order);
                            }}>
                            <UnsubIcon />
                        </IconButton>
                    );
                }
            }
        }];
    }

    getOptions = () => {
        return {
            filterType: 'dropdown',
            serverSide: true,
            print: false,
            sort: false,
            search: false,
            filter: false,
            downloadOptions: {
                filename: 'subscriptions.csv', separator: ','
            },
            selectableRows: 'none', // disable row selection
            onRowClick: (row) => { },
            onTableChange: (action, state) => { },
        };
    }

    loadOrdersAndServices() {
        const { service, user, getAllOrdersForService, getAllDevices } = this.props;
        this.setState({ needLoading: false });

        getAllOrdersForService(service._id, user); // Update orders list
        getAllDevices(user); // update devices list with licenses
    }

    render() {
        const { classes, service, data } = this.props;
        const { needLoading, open, device, order } = this.state;

        // When the page loads directly, we have to load the data.
        if (needLoading && service) {
            this.loadOrdersAndServices(); // TODO change location of this call.
        }

        return (
            <div>
                <ServiceHeader service={service} ></ServiceHeader>

                <Grid className={classes.root} container spacing={24}>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title={"Subscription List"}
                            data={data}
                            columns={this.getColumns()}
                            options={this.getOptions()}
                        />
                    </Grid>
                </Grid>
                {open && <OrderCancelDialog
                    open={open}
                    handleClose={this.handleClose}
                    device={device}
                    order={order}
                    service={service} />}
            </div>

        );
    }
}

const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
    } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
}

const mapStateToProps = (state, ownProps) => {

    let service = _.get(ownProps, 'match.params.id');
    service = service && state.services.byId[service];

    let orders = [];
    let data = [];

    let missing = {
        plans: [],
        licenses: [],
        devices: [],
    }

    // Rework store data
    if (service) {

        const loaded = !areDevicesLoading(state) && !areLicensesLoading(state)
            && !areOrdersLoading(state) && !arePlansLoading(state);

        if (loaded) {
            // Get all the orders for a service
            orders = _.chain(state.orders.byService[service._id])
                .map((order) => _.cloneDeep(state.orders.byId[order]))
                .compact()
                .value();

            _.each(orders, order => {
                order.planCopy = _.cloneDeep(state.plans.byId[order.plan_model]);
                order.licenseCopy = _.cloneDeep(state.licenses.byId[order.license]);
                if (!order.planCopy) missing.plans.push(order.plan_model);
                if (!order.licenseCopy) missing.licenses.push(order.license);
                else {
                    order.deviceCopy = _.cloneDeep(state.devices.byId[order.licenseCopy.device]);
                    if (!order.deviceCopy) missing.devices.push(order.licenseCopy.device);
                }
                order.renew_at = new Date(order.expires_at);
                order.expires_at = new Date(order.renew_at.getTime() + (parseInt(process.env.REACT_APP_LICENSE_LEEWAY, 10) * 1000));
            });
        }
    }

    // Create table data
    _.each(orders, (order) => {
        // Check if the order as expired.
        const hasExpired = Date.now() > order.expires_at;
        // Get the timezone of the user
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        // Get the language of the navigator.
        const locale = getNavigatorLanguage();

        // Format the status of the order
        let status = order.status;
        if (order.cancelationPending) status = 'canceled';
        if (order.status === 'failed') status = 'failed';
        // order is canceled if is really canceled or if a cancelation is pending.
        const isCanceled = status === 'canceled';
        const isFailed = status === 'failed';
        const isValid = ['trialing', 'active', 'paid', 'fulfilled'].indexOf(status) !== -1;

        let expiresAtFormat = order.expires_at.toLocaleString(locale, { timeZone });
        if (isCanceled) expiresAtFormat = (hasExpired ? 'Canceled' : 'Canceled at : ' + expiresAtFormat);
        if (isFailed) expiresAtFormat = '';

        let renewedAtFormat = order.renew_at.toLocaleString(locale, { timeZone });
        if (isCanceled) renewedAtFormat = 'Never';
        if (isFailed) renewedAtFormat = 'Once paid';

        const row = [
            order.deviceCopy ? order.deviceCopy.name : null, // Device
            order.planCopy ? order.planCopy.payment.interval : null, // Plan
            renewedAtFormat,
            expiresAtFormat,
            status,
            order.licenseCopy ? order.licenseCopy.activated : false, // Generated ?
            isValid,
        ];

        data.push(row);
    });

    return {
        user: state.users.authenticated,
        service,
        orders,
        missing,
        data,
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        getServiceDetails,
        getAllOrdersForService,
        getAllDevices,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(ServicesDetails));
