
export const SERVER_NETWORK_ERROR = '@server/NETWORK_ERROR';
export const INTERNAL_SERVER_ERROR = '@server/INTERNAL_ERROR';
export const CONNECTIVITY_LOSS_ERROR = '@connectivity/LOSS_ERROR';
export const CONNECTIVITY_GET_SUCCESS = '@connectivity/GET_SUCCESS';

export function catchNetworkError(e, dispatch) {

    if (e.message === 'Network Error') {
        dispatch({
            type: SERVER_NETWORK_ERROR,
            payload: { error: e },
            meta: { timestamp: Date.now() },
        });
        return true;
    }
    return false;
}

export function catchUnknownServerError(e, dispatch) {

    if (e.response && e.response.status === 500) {
        dispatch({
            type: INTERNAL_SERVER_ERROR,
            payload: { error: e },
            meta: { timestamp: Date.now() },
        });
        return true;
    }
    return false;
}

export function loseConnectivity() {
    return {
        type: CONNECTIVITY_LOSS_ERROR,
        payload: { error: 'No internet connectivity detected' },
        meta: { timestamp: Date.now(), },
    };
}

export function getConnectivity() {
    return {
        type: CONNECTIVITY_GET_SUCCESS,
        payload: { error: 'No internet connectivity detected' },
        meta: { timestamp: Date.now(), },
    };
}