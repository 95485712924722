import React from 'react';
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import WorkbenchLayout from './workbench-layout';
import OnBoardingLayout from './onboarding-layout';
import StaticLayout from './static-layout';
import { ErrorBoundary, NotificationHandler, NoMatch, Loader } from '../items/misc';

import { flagApplicationStarts, flagApplicationStops, getConnectivity, loseConnectivity } from '../../redux/actions';

const styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
    }
});

class PageLayout extends React.Component {

    state = {
        connectivity: true,
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        enqueueSnackbar: PropTypes.func.isRequired,
    }

    componentWillMount() {
        const { connectivity } = this.state;
        const { loseConnectivity, flagApplicationStarts } = this.props;

        this.interval = setInterval(() => {
            if (!navigator.onLine && connectivity) {
                loseConnectivity();
                this.setState({ connectivity: false });
            } else if (navigator.onLine && !connectivity) {
                getConnectivity();
                this.setState({ connectivity: true });
            }
        }, 10000);

        flagApplicationStarts();
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
        this.props.flagApplicationStops();
    }

    renderApplication() {
        const { location } = this.props;

        return (
            <Switch location={location}>
                <Route exact path='/' render={() => (<Redirect to="/workbench" />)} />
                <Route path='/onboarding' component={OnBoardingLayout} />
                <Route path='/workbench' component={WorkbenchLayout} />
                <Route path='/static' component={StaticLayout} />
                <Route /*no match*/ component={NoMatch} />
            </Switch>
        );
    }

    render() {
        const { application } = this.props;

        return (
            <ErrorBoundary>
                <NotificationHandler>
                    {application === 'started' && this.renderApplication()}
                    {application === 'starting' && <Loader message={'Wait while application is loading ... '} />}
                    {application === 'erred' && 'application is erred'}
                </NotificationHandler>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => ({
    user: state.users.authenticated,
    application: state.application.main,
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loseConnectivity,
        getConnectivity,
        flagApplicationStarts,
        flagApplicationStops,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)
    (withRouter(withSnackbar(withStyles(styles)(PageLayout))));