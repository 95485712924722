import { combineReducers } from 'redux';
import _ from 'lodash';

import {
    GET_LICENSE_SUCCESS, GET_LICENSE_ERROR, GET_LICENSE_PENDING,
} from '../actions';

function byDevice(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_LICENSE_SUCCESS:
            _.merge(state, { [payload.device]: payload._id });
            return state;
        default:
            return state;
    }
}

function byId(state = {}, { type, payload }) {

    switch (type) {
        case GET_LICENSE_SUCCESS:
            _.merge(state, { [payload._id]: payload });
            return state;
        default:
            return state;
    }

}

function allIds(state = [], { type, payload }) {

    state = _.clone(state);

    switch (type) {
        case GET_LICENSE_SUCCESS:
            !state.includes(payload._id) && state.push(payload._id);
            return state;
        default:
            return state;
    }
}

function isFetching(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_LICENSE_SUCCESS:
            return _.merge(state, { [payload._id]: false });
        case GET_LICENSE_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case GET_LICENSE_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

export default combineReducers({
    byId,
    byDevice,
    allIds,
    isFetching,
});
