// Wrapper around the actual Stripe <*Element>, so that it can be used as `inputComponent`
// for Material UI's <Input>. Also adds some styling.

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

const styles = (theme) => ({
    root: {
        width: '100%',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 1.5}px`,
        cursor: 'text',
    },
})

class StripeInput extends Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
        component: PropTypes.func.isRequired,
        componentProps: PropTypes.object,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onChange: PropTypes.func,
    }

    static defaultProps = {
        onFocus: () => { },
        onBlur: () => { },
        onChange: () => { },
    }

    render() {
        const { classes: c, theme, component: Component, componentProps, onFocus, onBlur, onChange, } = this.props

        return (
            <Component
                className={c.root}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                placeholder=""
                style={{
                    base: {
                        fontSize: `${theme.typography.fontSize}px`,
                        fontFamily: theme.typography.fontFamily,
                        color: '#000000de',
                    },
                }}
                {...componentProps}
            />
        )
    }
}

export default withStyles(styles, { withTheme: true })(StripeInput);