import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import _ from 'lodash';

import { withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
    Card, CardMedia, CardActions,
    CardActionArea, CardContent,
    Typography, IconButton
} from '@material-ui/core';
import {
    Add as AddIcon,
} from '@material-ui/icons';

import background from '../../../assets/images/illustration-b@2x.png';

const styles = theme => ({
    root: {
        margin: `-${theme.spacing.unit * 2}px -${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px -${theme.spacing.unit * 3}px`,
    },
    card: {
        borderRadius: 0,
        position: 'relative',
    },
    media: {
        backgroundColor: theme.palette.primary[theme.palette.type],
        backgroundPosition: 'top',
        minHeight: 340,
    },
    content: {
        position: 'absolute',
        left: 0,
        bottom: `${theme.spacing.unit * 4}px`,
        padding: `${theme.spacing.unit * 4}px`,
    },
    overlayTypo: {
        color: theme.palette.common.white,
    },
    companyTitle: {
        marginBottom: `${theme.spacing.unit * 5}px`,
    },
    heading: {
        width: '60%',
    },
    actions: {
        position: 'absolute',
        bottom: `${theme.spacing.unit * 2}px`,
        right: `${theme.spacing.unit * 3}px`,
    },
    icon: {
        color: theme.palette.primary[theme.palette.type],
        backgroundColor: theme.palette.common.white,
        border: `solid ${theme.palette.common.white} 2px`,
    }
});

class CompanyHeader extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    constructor (props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        const { classes, company } = this.props;

        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.media}
                        image={background}
                        title={(company && company.name) || 'company logo'}
                    />
                    <CardContent className={classes.content}>
                        <Typography className={classnames(classes.overlayTypo, classes.heading)} variant="h2" component="h1">
                            Active helps your team succeed.
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.actions}>

                    </CardActions>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {

    let company = _.get(state, 'companies.owned');
    company = company && state.companies.byId[company];

    return {
        user: state.users.authenticated,
        company,
    }
};

export default connect(mapStateToProps)(withStyles(styles)(CompanyHeader));
