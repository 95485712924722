import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { Workbench, Header, Sidebar } from './items';

const styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
    },
});

function workbenchLayout(props) {
    const title = process.env.REACT_APP_TITLE;

    return (
        <div className={props.classes.root} >
            <Header title={title} />
            <Sidebar />
            <Workbench />
        </div>
    )
}

export default withStyles(styles)(workbenchLayout);