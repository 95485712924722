import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import { Formik, Form } from 'formik';

import { stopReviewOnBoarding } from '../../../../redux/actions';

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
});


function exit(props) {

    const { classes, stopReviewOnBoarding } = props;

    return (
        <div>
            <Typography>
                Thank you for completing all your registration process.
                You can now use Active Licensing without limit!
            </Typography>
            <Formik className={classes.actionsContainer}
                initialValues={{}}
                onSubmit={(values, { setSubmitting }) => {
                    stopReviewOnBoarding();
                }}
                render={({ isSubmitting }) => (
                    <Form>
                        <Button variant="contained" color="primary" type="submit" disabled={isSubmitting} className={classes.button}>
                            Take me to Active Licensing
                        </Button>
                    </Form>)} />
        </div>
    )
}

/**
 * Map the consents of the authenticated user
 */
const mapStateToProps = state => {
    return {}
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        stopReviewOnBoarding,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(exit));
