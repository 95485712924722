import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { Header } from './items';
import { CompanyOnBoarding } from '../on-boarding';
import { Switch, Route, withRouter } from 'react-router-dom';

const styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
    },
});

function onboardingLayout(props) {

    const { classes, match } = props;
    const title = process.env.REACT_APP_TITLE;

    return (
        <div className={classes.root} >
            <Header title={title} />
            <Switch>
                <Route path='/' component={CompanyOnBoarding} />
            </Switch>
        </div>
    )
}

export default withRouter(withStyles(styles)(onboardingLayout));