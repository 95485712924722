
import { LOGIN_USER, startLogin, restoreSession, accessDenied, parseHash, logout, ACCESS_DENIED, SESSION_EXPIRED, SESSION_MISSING } from '../actions';
import { push, replace } from 'connected-react-router'

function parseSearchString(search) {
    const query = search.substring(1); // remove the '?' character
    const parts = query.split('&');
    const set = {};
    for (let part of parts) {
        const pair = part.split('=');
        set[pair[0]] = pair[1];
    }
    return set;
}

function formatSearchString(parsed) {
    let search = '?';
    for (let key in parsed) {
        search += `${key}=${parsed[key]}`;
    }
    if (search.length === 1) search = ''; // remove the '?' character
    return search;
}

function middlewareContext() {

    return store => next => action => {

        const dispatch = store.dispatch;
        let user = store.getState().users.authenticated;

        // Check that the session is still valid
        if (user.expiresAt && user.expiresAt <= Date.now()) {
            dispatch(logout());
        }

        // First handle the action then check the results
        const nextAction = next(action);

        // Handle automated behaviour in the application.
        const state = store.getState();
        user = state.users.authenticated;
        const { type, payload, meta } = action;

        switch (type) {

            // In case of access denied action, then start the login procedure
            case ACCESS_DENIED:
                dispatch(startLogin(state.router.location));
                break;

            case LOGIN_USER:
                if (meta.from === 'hash') {
                    // remove the hash in the location
                    dispatch(replace({ ...state.router.location, hash: '' }));
                }
                break;

            case '@@router/LOCATION_CHANGE':

                // On first rendering, try to restore the session
                if (payload.isFirstRendering) {
                    if (payload.location.pathname === '/') {
                        if (payload.location.hash.length > 0) {
                            dispatch(parseHash(payload.location.hash));
                        }
                    } else {
                        dispatch(restoreSession());
                    }
                }

                if (payload.location.search.length > 1) {
                    try {
                        const parsed = parseSearchString(payload.location.search);
                        if (parsed.redirectTo) {
                            const pathname = parsed.redirectTo;
                            // remove the redirection paramter
                            delete parsed.redirectTo;
                            // redirect with a new search string
                            dispatch(push({ ...payload.location, pathname, search: formatSearchString(parsed) }));
                        }
                        if (parsed.protected) {
                            delete parsed.protected;
                            const redirect = { pathname: payload.location.pathname, search: formatSearchString(parsed) };
                            dispatch(startLogin(redirect));
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    break;
                }

                break;

        }

        return nextAction;
    };

}

export default middlewareContext;