import React, { createElement } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Step, Stepper, StepLabel, Button, Typography, Paper } from '@material-ui/core';

import { Switch, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const styles = theme => ({
    root: {
        width: '100%',
        padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 5}px`,
    },
    button: {
        marginRight: theme.spacing.unit,
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    content: {
        flexGrow: 1,
        textAlign: 'center',
        padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 5}px`,
    },
    toolbar: theme.mixins.toolbar,
});

class HorizontalLinearStepper extends React.Component {

    static propTypes = {
        classes: PropTypes.object,
    }

    componentDidMount() {

    }

    isStepOptional(step) {
        return !!step.isOptional;
    }

    generateStep(step) {
        return step.component;
    }

    render() {
        const { classes, location, onboarding } = this.props;

        const { allSteps, current } = onboarding;

        return (
            <div className={classes.root}>
                <div className={classes.toolbar}></div>

                <Stepper activeStep={current}>
                    {allSteps.map((step, index) => {
                        const labelProps = {};
                        if (this.isStepOptional(step)) {
                            labelProps.optional = <Typography variant="caption">Optional</Typography>;
                        }
                        return (
                            <Step key={step.label} completed={!step.missing}>
                                <StepLabel {...labelProps}>{step.label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Paper square className={classes.content} elevation={0} >
                    <Switch location={location}>
                        {allSteps.map((step, index) => (<Route key={index} path={step.location} component={step.component} />))}
                    </Switch>
                </Paper>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    onboarding: state.onboarding,
    user: state.users.authenticated,
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(HorizontalLinearStepper));