import CompanyStep from './steps/company';
import CurrencyStep from './steps/currency';
import IntroStep from './steps/intro';
import ExitStep from './steps/exit';

export default [
    {
        label: 'Welcome',
        name: 'welcome',
        optional: false,
        missing: false,
        component: IntroStep,
        location: '/onboarding/welcome'
    },
    {
        label: 'Information',
        name: 'company',
        optional: false,
        missing: false,
        component: CompanyStep,
        location: '/onboarding/company'
    },
    {
        label: 'Choose currency',
        name: 'currency',
        optional: false,
        missing: false,
        component: CurrencyStep,
        location: '/onboarding/currency'
    },
    {
        label: 'Review',
        name: 'thankyou',
        optional: false,
        missing: false,
        component: ExitStep,
        location: '/onboarding/thankyou'
    },
]