import axios from 'axios';
import _ from 'lodash';

import { catchNetworkError, catchUnknownServerError } from './server';
import { getAllPlansByServiceSuccess, getPlanSuccess } from './plans';

////////////////////////////////////////////////////
//  Action Types
////////////////////////////////////////////////////

export const GET_ALL_PAGES_SUCCESS = '@pages/GET_ALL_SUCCESS';
export const GET_ALL_PAGES_ERROR = '@pages/GET_ALL_ERROR';
export const GET_ALL_PAGES_PENDING = '@pages/GET_ALL_PENDING';

export const GET_PAGE_SUCCESS = '@pages/GET_ONE_SUCCESS';
export const GET_PAGE_ERROR = '@pages/GET_ONE_ERROR';
export const GET_PAGE_PENDING = '@pages/GET_ONE_PENDING';

////////////////////////////////////////////////////
//  Action Creators
////////////////////////////////////////////////////

export function getAllPagesPending() {
    return {
        type: GET_ALL_PAGES_PENDING,
        payload: {},
        meta: { timestamp: Date.now() },
    }
}

export function getAllPagesSuccess(pages) {
    return {
        type: GET_ALL_PAGES_SUCCESS,
        payload: pages,
        meta: { timestamp: Date.now() },
    }
}

export function getAllPagesError(e, hidden = false) {
    return {
        type: GET_ALL_PAGES_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now() },
    }
}

export function getPagePending(id) {
    return {
        type: GET_PAGE_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getPageSuccess(page) {
    return {
        type: GET_PAGE_SUCCESS,
        payload: page,
        meta: { timestamp: Date.now(), target: page._id },
    }
}

export function getPageError(id, e, hidden = false) {
    return {
        type: GET_PAGE_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: id },
    }
}

/**
 * Fetch all the services available for the user.
 * Populate the services with their infos automatically.
 */
export function getAllPages() {

    return (dispatch, getState) => {

        const pending = isGetAllPagesPending(getState());

        if (!pending) {

            dispatch(getAllPagesPending());

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: '/static/pages',
            }).then(res => {

                const pages = res.data.data;
                dispatch(getAllPagesSuccess(pages.map(p => p.id)));

                _.each(pages, page => {
                    dispatch(getPageSuccess(page.data));
                });

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getAllPagesError(e, flag));
            });
        }

    }

}
/**
 * Never used, page is already populated when fetching all.
 */
export function getPage(page) {
    return (dispatch, getState) => {

        const existing = isPagePresent(getState(), page);
        const pending = isGetPagePending(getState(), page);

        if (!existing && !pending) {

            dispatch(getPagePending(page));

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/static/pages/${page}`,
            }).then(res => {

                const page = res.data.data;
                dispatch(getPageSuccess(page));

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getPageError(page, e, flag));
            });
        }
    }
}

////////////////////////////////////////////////////
//  Status check
////////////////////////////////////////////////////

export function isGetAllPagesPending(state) {
    return !!state.static.pages.isFetchingAll;
}

export function isGetPagePending(state, id) {
    return !!state.static.pages.isFetching[id];
}

export function isPagePresent(state, id) {
    return !!state.static.pages.byId[id];
}