import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';


const styles = theme => ({
    container: {
        margin: theme.spacing.unit * 2,
    },
    title: {
        marginTop: theme.spacing.unit * 3,
    },
    subtitle: {
        marginTop: theme.spacing.unit * 2,
    },
    content: {
        marginTop: theme.spacing.unit * 2,
    },
    section: {
        margin: theme.spacing.unit * 3,
    },
});


function Page(props) {

    const { classes, page } = props;

    return (
        <div className={classes.container}>
            <Typography variant="h3" className={classes.title}>
                {page.description.title}
            </Typography>
            <Typography variant="h5" className={classes.subtitle}>
                {page.description.sub_title}
            </Typography>
            <Grid container spacing={24}>
                {page.sections.map((section, index) => (
                    <Grid key={index} item xs={12} className={classes.section}>
                        <Typography variant="h4" className={classes.title}>
                            {section.title}
                        </Typography>
                        <Typography variant="h6" className={classes.subtitle}>
                            {section.sub_title}
                        </Typography>
                        <Typography className={classes.content}>
                            {section.content}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </div >
    )
}

export default withStyles(styles)(Page);
