import axios from 'axios';
import _ from 'lodash';

////////////////////////////////////////////////////
//  Action Types
////////////////////////////////////////////////////

export const APPLICATION_STARTING = '@app/STARTS';
export const APPLICATION_STARTED = '@app/STARTED';
export const APPLICATION_STOPPING = '@app/STOPS';
export const APPLICATION_STOPPED = '@app/STOPPED';

export const WORKBENCH_STARTING = '@app/WORKBENCH_STARTS';
export const WORKBENCH_STARTED = '@app/WORKBENCH_STARTED';
export const WORKBENCH_STOPPING = '@app/WORKBENCH_STOPS';
export const WORKBENCH_STOPPED = '@app/WORKBENCH_STOPPED';


////////////////////////////////////////////////////
//  Action Creators
////////////////////////////////////////////////////

/**
 * Used when the workbench is starting. The 'Real' application is running,
 * so we can react to it in the middlewares.
 */
export function flagApplicationStarts() {
    return {
        type: APPLICATION_STARTING,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

export function flagApplicationHasStarted() {
    return {
        type: APPLICATION_STARTED,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

/**
 * Used when the workbench is starting. The 'Real' application is running,
 * so we can react to it in the middlewares.
 */
export function flagApplicationStops() {
    return {
        type: APPLICATION_STOPPING,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

export function flagApplicationHasStopped() {
    return {
        type: APPLICATION_STOPPED,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

/**
 * Used when the workbench is starting. The 'Real' application is running,
 * so we can react to it in the middlewares.
 */
export function flagWorkbenchStarts() {
    return {
        type: WORKBENCH_STARTING,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

export function flagWorkbenchHasStarted() {
    return {
        type: WORKBENCH_STARTED,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

/**
 * Used when the workbench is starting. The 'Real' application is running,
 * so we can react to it in the middlewares.
 */
export function flagWorkbenchStops() {
    return {
        type: WORKBENCH_STOPPING,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

export function flagWorkbenchHasStopped() {
    return {
        type: WORKBENCH_STOPPED,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}