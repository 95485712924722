import axios from 'axios';
import _ from 'lodash';

import { catchNetworkError, catchUnknownServerError } from './server';

////////////////////////////////////////////////////
//  Action Types
////////////////////////////////////////////////////

export const GET_ALL_COMPANIES_SUCCESS = '@companies/GET_ALL_SUCCESS';
export const GET_ALL_COMPANIES_ERROR = '@companies/GET_ALL_ERROR';
export const GET_ALL_COMPANIES_PENDING = '@companies/GET_ALL_PENDING';

export const CREATE_OWNED_COMPANY_SUCCESS = '@companies/CREATE_OWNED_SUCCESS';
export const CREATE_OWNED_COMPANY_ERROR = '@companies/CREATE_OWNED_ERROR';
export const CREATE_OWNED_COMPANY_PENDING = '@companies/CREATE_OWNED_PENDING';

export const GET_COMPANY_SUCCESS = '@companies/GET_SUCCESS';
export const GET_COMPANY_ERROR = '@companies/GET_ERROR';
export const GET_COMPANY_PENDING = '@companies/GET_PENDING';

export const UPDATE_COMPANY_SUCCESS = '@companies/UPDATE_SUCCESS';
export const UPDATE_COMPANY_ERROR = '@companies/UPDATE_ERROR';
export const UPDATE_COMPANY_PENDING = '@companies/UPDATE_PENDING';

export const GET_COMPANY_BILLING_SUCCESS = '@companies/GET_BILLING_SUCCESS';
export const GET_COMPANY_BILLING_ERROR = '@companies/GET_BILLING_ERROR';
export const GET_COMPANY_BILLING_PENDING = '@companies/GET_BILLING_PENDING';

export const ATTACH_SOURCE_PAYMENT_SUCCESS = '@companies/ATTACH_SOURCE_PAYMENT_SUCCESS';
export const ATTACH_SOURCE_PAYMENT_ERROR = '@companies/ATTACH_SOURCE_PAYMENT_ERROR';
export const ATTACH_SOURCE_PAYMENT_PENDING = '@companies/ATTACH_SOURCE_PAYMENT_PENDING';

export const STRIPE_SOURCE_ERROR = '@companies/STRIPE_SOURCE_ERROR';

////////////////////////////////////////////////////
//  Action Creators
////////////////////////////////////////////////////

export function catchStripeError(error) {
    return {
        type: STRIPE_SOURCE_ERROR,
        payload: error,
        meta: { timestamp: Date.now(), }
    }
}

export function attachSourcePending(company) {
    return {
        type: ATTACH_SOURCE_PAYMENT_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: company._id },
    }
}

export function attachSourceSuccess(source, company) {
    return {
        type: ATTACH_SOURCE_PAYMENT_SUCCESS,
        payload: source,
        meta: { timestamp: Date.now(), target: company._id },
    }
}

export function attachSourceError(company, e, hidden = false) {
    return {
        type: ATTACH_SOURCE_PAYMENT_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: company._id },
    }
}

/**
 * Attach the source object to the company.
 * @param {*} user 
 * @param {*} company 
 * @param {*} source 
 */
export function attachSource(user, company, source) {

    return (dispatch, getState) => {

        const pending = isAttachingSourcePending(getState(), company);

        if (!pending) {
            dispatch(attachSourcePending(company));

            axios({
                method: 'put',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/companies/${company._id}/billing/sources`,
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                },
                data: { source: source.id }
            }).then(res => {
                // Get only the first item in the list
                const source = res.data.data;
                dispatch(attachSourceSuccess(source, company));
            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(attachSourceError(company, e, flag));
            });
        }
    }

}

export function createCompanyPending() {
    return {
        type: CREATE_OWNED_COMPANY_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: null },
    }
}

export function createCompanySuccess(company) {
    return {
        type: CREATE_OWNED_COMPANY_SUCCESS,
        payload: company,
        meta: { timestamp: Date.now(), target: null },
    }
}

export function createCompanyError(e, hidden = false) {
    return {
        type: CREATE_OWNED_COMPANY_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: null },
    }
}

/**
 * Try to fetch the company that the user own.
 */
export function createUserOwnedCompany(user, company) {

    return (dispatch, getState) => {
        const pending = isCreateCompanyPending(getState());

        if (!pending) {
            dispatch(createCompanyPending());

            axios({
                method: 'put',
                baseURL: process.env.REACT_APP_API_HOST,
                url: '/companies',
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                },
                data: company
            }).then(res => {

                // Get only the first item in the list
                const company = res.data.data;
                dispatch(createCompanySuccess(company));

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(createCompanyError(e, flag));
            });
        }
    }

}

export function getAllCompaniesPending() {
    return {
        type: GET_ALL_COMPANIES_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: null },
    }
}

export function getAllCompaniesSuccess(companies) {
    return {
        type: GET_ALL_COMPANIES_SUCCESS,
        payload: companies,
        meta: { timestamp: Date.now(), target: null },
    }
}

export function getAllCompaniesError(e, hidden = false) {
    return {
        type: GET_ALL_COMPANIES_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: null },
    }
}

/**
 * Try to fetch the company that the user own.
 */
export function getAllCompanies(user) {

    return (dispatch, getState) => {

        const pending = isGetAllCompaniesPending(getState());

        if (!pending) {
            dispatch(getAllCompaniesPending());

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: '/companies',
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {
                // Get only the first item in the list
                const companies = res.data.data;
                dispatch(getAllCompaniesSuccess(companies));
                _.each(companies, company => {
                    dispatch(getCompany(company.id, user));
                    dispatch(getCompanyBilling(company.id, user));
                });
            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getAllCompaniesError(e, flag));
            });
        }
    }

}

export function getCompanyBillingPending(id) {
    return {
        type: GET_COMPANY_BILLING_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getCompanyBillingSuccess(billing, id) {
    return {
        type: GET_COMPANY_BILLING_SUCCESS,
        payload: billing,
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getCompanyBillingError(id, e, hidden = false) {
    return {
        type: GET_COMPANY_BILLING_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getCompanyBilling(id, user, force = false) {

    return (dispatch, getState) => {

        const pending = isGetCompanyBillingPending(getState(), id);
        const existing = isCompanyBillingPresent(getState(), id);

        if (!pending && (!existing || force)) {
            dispatch(getCompanyBillingPending(id));

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/companies/${id}/billing`,
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {
                const billing = res.data.data;
                dispatch(getCompanyBillingSuccess(billing, id));
            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getCompanyBillingError(id, e, flag));
            });
        }
    }

}

export function getCompanyPending(id) {
    return {
        type: GET_COMPANY_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getCompanySuccess(company) {
    return {
        type: GET_COMPANY_SUCCESS,
        payload: company,
        meta: { timestamp: Date.now(), target: company._id },
    }
}

export function getCompanyError(id, e, hidden = false) {
    return {
        type: GET_COMPANY_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getCompany(id, user, force = false) {

    return (dispatch, getState) => {

        const pending = isGetCompanyPending(getState(), id);
        const existing = isCompanyPresent(getState(), id);

        if (!pending && (!existing || force)) {
            dispatch(getCompanyPending(id));

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/companies/${id}`,
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {
                const company = res.data.data;
                dispatch(getCompanySuccess(company));
            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getCompanyError(id, e, flag));
            });
        }
    }

}

export function updateCompanyPending(company) {
    return {
        type: UPDATE_COMPANY_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: company._id },
    }
}

export function updateCompanySuccess(company) {
    return {
        type: UPDATE_COMPANY_SUCCESS,
        payload: company,
        meta: { timestamp: Date.now(), target: company._id },
    }
}

export function updateCompanyError(company, e, hidden = false) {
    return {
        type: UPDATE_COMPANY_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: company._id },
    }
}

export function updateCompany(company, updates, user) {

    return (dispatch, getState) => {

        const pending = isUpdateCompanyPending(getState(), company);

        if (!pending) {
            dispatch(updateCompanyPending(company));

            // Create a clone of the company to push all the required elements each time.
            updates = Object.assign({
                name: company.name,
                address: _.clone(company.address),
                payment: { currency: company.payment.currency }
            },
                updates);

            axios({
                method: 'post',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/companies/${company._id}`,
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                },
                data: updates
            }).then(res => {
                const company = res.data.data;
                dispatch(updateCompanySuccess(company));
            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(updateCompanyError(company, e, flag));
            });
        }
    }

}

////////////////////////////////////////////////////
//  Status check
////////////////////////////////////////////////////

export function isGetCompanyPending(state, id) {
    return !!state.companies.isFetching[id];
}

export function areGetCompaniesPending(state) {
    return !!_.find(state.companies.isFetching, (isFetching) => {
        return _.isNumber(isFetching);
    });
}

export function isOwnedCompanyPresent(state) {
    return isCompanyPresent(state, state.companies.owned);
}

export function isCompanyPresent(state, id) {
    return !!state.companies.byId[id];
}

export function isGetAllCompaniesPending(state) {
    return !!state.companies.isFetchingAll;
}

/**
 * Only one company creation is allowed at a time
 */
export function isCreateCompanyPending(state) {
    return !!state.companies.isCreating;
}

export function isCreateCompanyErred(state) {
    const status = state.companies.isCreating;
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}

export function isUpdateCompanyPending(state, company) {
    return !!state.companies.isUpdating[company._id];
}

export function isUpdateCompanyErred(state, company) {
    const status = state.companies.isUpdating[company._id];
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}

export function isAttachingSourcePending(state, company) {
    return !!state.companies.isAttachingSource[company._id];
}

export function isAttachingSourceErred(state, company) {
    const status = state.companies.isAttachingSource[company._id];
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}

export function isGetCompanyBillingPending(state, id) {
    return !!state.companies.isFetchingBilling[id];
}

export function isCompanyBillingPresent(state, id) {
    return !!state.companies.billingByCompany[id];
}

export function areGetCompanyBillingsPending(state) {
    return !!_.find(state.companies.isFetchingBilling, (isFetching) => {
        return _.isNumber(isFetching);
    });
}