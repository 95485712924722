
export const START_ON_BOARDING = '@on-boarding/START';
export const STOP_ON_BOARDING = '@on-boarding/STOP';
export const ON_BOARDING_NAMING_ERROR = '@on-boarding/NAMING_ERROR';
export const NEXT_ON_BOARDING_STEP = '@on-boarding/NEXT_STEP';
export const SET_CURRENT_ON_BOARDING_STEP = '@on-boarding/SET_CURRENT_STEP';
export const ACTIVATE_ON_BOARDING = '@on-boarding/ACTIVATE';
export const DEACTIVATE_ON_BOARDING = '@on-boarding/DEACTIVATE';

export function nextStep() {
    return {
        type: NEXT_ON_BOARDING_STEP,
    }
}

export function setCurrentStep(idx) {
    return {
        type: SET_CURRENT_ON_BOARDING_STEP,
        payload: idx
    }
}

export function startConsentsOnBoarding() {
    return {
        type: START_ON_BOARDING,
        payload: 'welcome'
    }
}

export function stopConsentsOnBoarding() {
    return {
        type: STOP_ON_BOARDING,
        payload: 'welcome'
    }
}

export function startCompanyOnBoarding() {
    return {
        type: START_ON_BOARDING,
        payload: 'company'
    }
}

export function stopCompanyOnBoarding() {
    return {
        type: STOP_ON_BOARDING,
        payload: 'company'
    }
}

export function startCurrencyOnBoarding() {
    return {
        type: START_ON_BOARDING,
        payload: 'currency'
    }
}

export function stopCurrencyOnBoarding() {
    return {
        type: STOP_ON_BOARDING,
        payload: 'currency'
    }
}

export function startReviewOnBoarding() {
    return {
        type: START_ON_BOARDING,
        payload: 'thankyou'
    }
}

export function stopReviewOnBoarding() {
    return {
        type: STOP_ON_BOARDING,
        payload: 'thankyou'
    }
}

export function onBoardingNamingError(reason) {
    return {
        type: ON_BOARDING_NAMING_ERROR,
        payload: reason
    }
}