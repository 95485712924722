import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    getAllDevices,
    isGetAllDevicesPending, isGetAllDevicesErred,
} from '../../../../../redux/actions';

const styles = theme => ({
    root: {
        marginTop: `${theme.spacing.unit * 3}px`
    },
    explaination: {
        textAlign: 'center',
    },
});

class chooseMethod extends React.Component {

    state = {
        error: null
    }

    constructor(props) {
        super(props);
    }

    submit(result) {
        const { save, user, getAllDevices } = this.props;
        save(result);
        // Always get all devices.
        getAllDevices(user);
    }

    componentDidUpdate() {
        const { next, isDevicesFetchingPending, isDevicesFetchingErred,
            operationPending, operationFinished, isDisabled } = this.props;

        // Once the device is created, we have to disable the form.
        if (isDevicesFetchingPending && !isDisabled) {
            operationPending();
        }

        if (isDisabled) {
            if (isDevicesFetchingPending === false) {
                operationFinished();
                next();
            } else if (isDevicesFetchingErred) {
                operationFinished();
                this.setState({ error: 'Cannot get the devices list.' });
            }
        }

    }

    render() {
        const { classes, service, isDisabled } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography className={classes.explaination}>
                            You are going to license a new {service.description.device_name || 'device'} to {service.description.title || service.name}.
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" disabled={isDisabled} onClick={() => this.submit('create')}
                            color="primary" fullWidth>
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </div >
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        isDevicesFetchingPending: isGetAllDevicesPending(state),
        isDevicesFetchingErred: isGetAllDevicesErred(state),
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        getAllDevices,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(chooseMethod));
