import axios from 'axios';
import _ from 'lodash';

import { catchNetworkError, catchUnknownServerError } from './server';

////////////////////////////////////////////////////
//  Action Types
////////////////////////////////////////////////////

export const GET_LICENSE_SUCCESS = '@licenses/GET_SUCCESS';
export const GET_LICENSE_ERROR = '@licenses/GET_ERROR';
export const GET_LICENSE_PENDING = '@licenses/GET_PENDING';

////////////////////////////////////////////////////
//  Action Creators
////////////////////////////////////////////////////

export function getLicensePending(id) {
    return {
        type: GET_LICENSE_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getLicenseSuccess(license) {
    return {
        type: GET_LICENSE_SUCCESS,
        payload: license,
        meta: { timestamp: Date.now(), target: license._id },
    }
}

export function getLicenseError(id, e, hidden = false) {
    return {
        type: GET_LICENSE_ERROR,
        payload: { error: e, hidden},
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getLicenseDetails(id, user, force = false) {
    return (dispatch, getState) => {

        const pending = isGetLicensePending(getState(), id);
        const existing = isLicensePresent(getState(), id);

        if (!pending && (force || !existing)) {

            dispatch(getLicensePending(id));

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/licenses/${id}`,
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {

                const license = res.data.data;
                dispatch(getLicenseSuccess(license));

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getLicenseError(id, e, flag));
            });

        }
    }
}

////////////////////////////////////////////////////
//  Status check
////////////////////////////////////////////////////

export function isGetLicensePending(state, id) {
    return !!state.licenses.isFetching[id];
}

export function isLicensePresent(state, id) {
    return !!state.licenses.byId[id];
}

export function areLicensesLoading(state) {
    return _.find(state.licenses.isFetching, (isFetching) => {
        return _.isNumber(isFetching); // error or false
    });
}