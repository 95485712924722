import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogContent, DialogTitle,
    IconButton, LinearProgress, Grid, Button,
} from '@material-ui/core';
import {
    Close as CloseIcon
} from '@material-ui/icons';

import { Form } from 'formik';

import {
    CardNumberElement, CardExpiryElement, CardCVCElement,
} from 'react-stripe-elements';
import StripeElementWrapper from '../wrap/stripe-element-wrapper';

import AddSourceController from './controller';


const styles = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
});

class AddSourceDialog extends React.Component {

    state = {
        isSubmitting: false,
    }

    static propTypes = {
        handleClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
    }

    render() {

        const { classes, handleClose, open } = this.props;

        return (
            <AddSourceController
                onSuccess={() => {
                    handleClose();
                }}
                render={({ isSubmitting }) => (
                    <Dialog
                        open={open}
                        onClose={() => {
                            if (!isSubmitting) handleClose();
                        }}
                        aria-labelledby={`new-source`}
                    >
                        {isSubmitting && <LinearProgress />}
                        <DialogTitle id={`new-source`}>Add a new credit card</DialogTitle>
                        <IconButton aria-label="Close" className={classes.closeButton}
                            onClick={() => {
                                if (!isSubmitting) handleClose();
                            }}
                            disabled={isSubmitting}>
                            <CloseIcon />
                        </IconButton>
                        <DialogContent>
                            <Form>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <StripeElementWrapper label="Card Number"
                                            component={CardNumberElement} disabled={isSubmitting} />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <StripeElementWrapper label="Expiry (MM / YY)"
                                            component={CardExpiryElement} disabled={isSubmitting} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <StripeElementWrapper label="CVC"
                                            component={CardCVCElement} disabled={isSubmitting} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained"
                                            color="primary" fullWidth disabled={isSubmitting}>
                                            Register Credit Card
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        </DialogContent>
                    </Dialog>

                )} />

        );
    }
}

export default withStyles(styles)(AddSourceDialog);