import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {

} from '../../../../redux/actions';


class EditSourceController extends React.Component {

    static propTypes = {
        render: PropTypes.func.isRequired,
        data: PropTypes.array.isRequired,
        sources: PropTypes.array.isRequired,
    }

    handleDelete = (index) => {
        const { sources } = this.props;
        const source = sources[index];

        throw new Error('Not implemented');
    }

    isDeleteEnabled = (index) => {
        const { sources } = this.props;
        //return sources[index].status === 'chargeable';
        return false;
    }

    render() {
        const { render, data, sources } = this.props;
        const { handleDelete, isDeleteEnabled } = this;
        return React.createElement(render, { data, sources, handleDelete, isDeleteEnabled });
    }
}

const mapStateToProps = (state) => {

    const company = state.companies.byId[state.companies.owned];
    const billing = state.companies.billingByCompany[state.companies.owned];

    const sources = billing ? billing.sources.data : [];

    const data = _.map(sources, (source) => {
        const type = source.type;
        const month = source.card.exp_month > 9 ? source.card.exp_month : '0' + source.card.exp_month;
        const status = 'valid';
        return [
            billing.default_source === source.id, // default
            type, // type
            `**** **** **** ${source.card.last4}`, // identifier,
            `${month}/${source.card.exp_year}`, // expiration
            status, // status of the card
        ];
    });

    return {
        user: state.users.authenticated,
        company,
        billing,
        sources,
        data,
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(EditSourceController);
