import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';

import { CircularProgress, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        margin: 'auto',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
});


class Loader extends React.Component {

    render() {
        const { classes, message } = this.props;

        return (
            <div className={classes.root}>
                {message && <Typography component='h3'>{message}</Typography>}
                <CircularProgress className={classes.progress} />
            </div>
        );
    }

}

export default withStyles(styles)(Loader);