import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import _ from 'lodash';

import MUIDataTable from 'mui-datatables';

import { withStyles } from '@material-ui/core/styles';
import { IconButton, Chip } from '@material-ui/core';
import { DeleteForever as DeleteIcon } from '@material-ui/icons';


const styles = theme => ({
    table: {
        width: '100%',
    },
    chip: {
        margin: theme.spacing.unit,
    },
    chipWhite: {
        color: theme.palette.common.white,
    }
});

class SourcesTable extends React.Component {

    state = {}

    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    getColumns = () => {
        return [
            {
                name: '',
                options: {
                    filter: false,
                    sort: false,
                    searchable: false,
                    empty: false,
                    customBodyRender: (value) => {

                        const { classes } = this.props;

                        return (
                            value ?
                                <Chip color='primary' label="Default" className={classnames(classes.chip, classes.chipWhite)} />
                                : null
                        );
                    }
                }
            }, {
                name: 'Type',
                options: {
                    hint: 'The type of the payment method (Credit Card or Bank Account)',
                }
            }, {
                name: 'Identifier',
                options: {
                    hint: 'The identifier of the payment method',
                }
            }, {
                name: 'Expires At',
                options: {
                    hint: 'The date when the payment method is expired',
                }
            }, {
                name: 'Status',
                options: {
                    filter: false,
                    sort: false,
                    searchable: false,
                    customBodyRender: (value) => {

                        const { classes } = this.props;

                        return (
                            <Chip label={value} className={classnames(classes.chip)} />
                        );
                    }
                }
            }, {
                name: 'Delete',
                options: {
                    filter: false,
                    sort: false,
                    searchable: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const { handleDelete, isDeleteEnabled } = this.props;
                        const disabled = !handleDelete || (isDeleteEnabled && !isDeleteEnabled(tableMeta.rowIndex));

                        return (
                            <IconButton disabled={disabled}
                                color='secondary'
                                onClick={() => {
                                    handleDelete(tableMeta.rowIndex);
                                }}>
                                <DeleteIcon />
                            </IconButton>
                        );
                    }
                }
            }];
    }

    getOptions = () => {
        return {
            filterType: 'dropdown',
            serverSide: true,
            print: false,
            downloadOptions: {
                filename: 'payment-methods.csv', separator: ','
            },
            selectableRows: 'none', // disable row selection
            onRowClick: (row) => {},
            onTableChange: (action, state) => {},
        };
    }

    render() {
        const { classes, data } = this.props;

        return (
            <MUIDataTable className={classes.table}
                title={"Payment methods"}
                data={data}
                columns={this.getColumns()}
                options={this.getOptions()}
                fullWidth
            />
        );
    }
}

export default withStyles(styles)(SourcesTable);
