import React from 'react';

import _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withSnackbar } from 'notistack';

import { Button } from '@material-ui/core';

import {
    pushSuccess,
    pushError,
    notifShown,
    notifDismissed,
} from '../../../redux/actions';


class NotificationHandler extends React.Component {

    componentDidUpdate() {

        const { notifs, enqueueSnackbar, notifShown, notifDismissed } = this.props;

        _.chain(notifs).filter(n => n.show).each(n => {
            enqueueSnackbar(n.message, {
                key: n.key,
                variant: n.level,
                autoHideDuration: n.duration,
                preventDuplicate: n.preventDuplicate,
                persist: n.persist,
                action: n.persist && n.action ?
                    (<Button onClick={() => notifDismissed(n)}>
                        {n.action}
                    </Button>) : null,
                onClose: () => { notifDismissed(n) }
            });
            notifShown(n);
        }).value();

    }

    render() {
        const { children } = this.props;
        return children;
    }

}

const mapStateToProps = state => ({
    notifs: state.notifications.all,
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        pushSuccess,
        pushError,
        notifShown,
        notifDismissed,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(NotificationHandler));