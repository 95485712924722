import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, MenuItem, FormHelperText } from '@material-ui/core';

import { Formik, Field, Form } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';

const styles = theme => ({
    root: {
        marginTop: `${theme.spacing.unit * 3}px`
    },
    explaination: {
        textAlign: 'center',
    },
});

const PlanSchema = Yup.object().shape({
    plan: Yup.string()
        .required('Required'),
});

const currencyMapping = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
}

const periodMapping = {
    'lifetime': 'once',
    'day': 'daily',
    'month': 'monthly',
    'year': 'yearly',
}


class ChoosePlan extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { classes, planid, previous, next, save, plans, company } = this.props;

        const planList = _.compact(_.map(plans, (plan) => {
            const { amount, currency, interval, active } = plan.payment;
            
            // filter out some elements
            if (currency !== company.payment.currency) return null;
            if (!active) return null;
            
            return {
                id: plan._id,
                description: `${amount / 100}${currencyMapping[currency]} paid ${periodMapping[interval]}`,
            }
        }));

        return (
            <div className={classes.root}>
                <Formik
                    initialValues={{ plan: planid || '' }}
                    validationSchema={PlanSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        save(values.plan);
                        setSubmitting(false);
                        next();
                    }}

                    render={({ errors, isSubmitting }) => {

                        return (
                            <Form>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.explaination}>
                                            Choose your pricing plan
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div>
                                            <Field name="plan" component={Select}
                                                placeholder="Choose the pricing" label="Pricing" fullWidth>
                                                ({planList.map((plan, index) => {
                                                    return <MenuItem key={index} value={plan.id}>{plan.description}</MenuItem>;
                                                })})
                                            </Field>
                                            {errors.plan && <FormHelperText error>{errors.plan}</FormHelperText>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" disabled={isSubmitting} onClick={previous} color="primary" fullWidth>
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Button variant="contained" disabled={isSubmitting} type='submit' color="primary" fullWidth>
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }} />
            </div >
        )
    }

}

export default withStyles(styles)(ChoosePlan);
