import axios from 'axios';
import _ from 'lodash';

import { catchNetworkError, catchUnknownServerError } from './server';
import { getAllPlansByServiceSuccess, getPlanSuccess } from './plans';

////////////////////////////////////////////////////
//  Action Types
////////////////////////////////////////////////////

export const GET_ALL_SERVICES_SUCCESS = '@services/GET_ALL_SUCCESS';
export const GET_ALL_SERVICES_ERROR = '@services/GET_ALL_ERROR';
export const GET_ALL_SERVICES_PENDING = '@services/GET_ALL_PENDING';

export const GET_SERVICE_SUCCESS = '@services/GET_ONE_SUCCESS';
export const GET_SERVICE_ERROR = '@services/GET_ONE_ERROR';
export const GET_SERVICE_PENDING = '@services/GET_ONE_PENDING';

////////////////////////////////////////////////////
//  Action Creators
////////////////////////////////////////////////////

export function getAllServicesPending() {
    return {
        type: GET_ALL_SERVICES_PENDING,
        payload: {},
        meta: { timestamp: Date.now() },
    }
}

export function getAllServicesSuccess(services) {
    return {
        type: GET_ALL_SERVICES_SUCCESS,
        payload: services,
        meta: { timestamp: Date.now() },
    }
}

export function getAllServicesError(e, hidden = false) {
    return {
        type: GET_ALL_SERVICES_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now() },
    }
}

export function getServicePending(id) {
    return {
        type: GET_SERVICE_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getServiceSuccess(service) {
    return {
        type: GET_SERVICE_SUCCESS,
        payload: service,
        meta: { timestamp: Date.now(), target: service._id },
    }
}

export function getServiceError(id, e, hidden = false) {
    return {
        type: GET_SERVICE_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: id },
    }
}

/**
 * Fetch all the services available for the user.
 * Populate the services with their infos automatically.
 */
export function getAllServices() {

    return (dispatch, getState) => {

        const pending = isGetAllServicesPending(getState());

        if (!pending) {

            dispatch(getAllServicesPending());

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: '/services/',
            }).then(res => {

                const services = res.data.data;
                dispatch(getAllServicesSuccess(services));

                _.each(services, service => {
                    dispatch(getServiceDetails(service.id));
                });

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getAllServicesError(e, flag));
            });
        }

    }

}

export function getServiceDetails(service) {
    return (dispatch, getState) => {

        const existing = isServicePresent(getState(), service);
        const pending = isGetServicePending(getState(), service);

        if (!existing && !pending) {

            dispatch(getServicePending(service));

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/services/${service}`,
            }).then(res => {

                const service = res.data.data;
                const plans = service.plans; // service.plans is modified in reducer.
                dispatch(getServiceSuccess(service));

                // Store plans list
                dispatch(getAllPlansByServiceSuccess(service._id, _.map(plans, (plan) => ({ id: plan._id }))));
                // Update plans data
                _.each(plans, (plan) => {
                    dispatch(getPlanSuccess(plan));
                });

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getServiceError(service, e, flag));
            });
        }
    }
}

////////////////////////////////////////////////////
//  Status check
////////////////////////////////////////////////////

export function isGetAllServicesPending(state) {
    return !!state.services.isFetchingAll;
}

export function isGetServicePending(state, id) {
    return !!state.services.isFetching[id];
}

export function isServicePresent(state, id) {
    return !!state.services.byId[id];
}

export function areServicesLoading(state) {
    return _.find(state.services.isFetching, (isFetching) => {
        return _.isNumber(isFetching); // error or false
    });
}