import axios from 'axios';
import _ from 'lodash';

import { catchNetworkError, catchUnknownServerError } from './server';
import { getLicenseDetails } from './licenses';

////////////////////////////////////////////////////
//  Action Types
////////////////////////////////////////////////////

export const GET_ALL_ORDERS_SUCCESS = '@orders/GET_ALL_SUCCESS';
export const GET_ALL_ORDERS_ERROR = '@orders/GET_ALL_ERROR';
export const GET_ALL_ORDERS_PENDING = '@orders/GET_ALL_PENDING';

export const GET_ORDER_SUCCESS = '@orders/GET_SUCCESS';
export const GET_ORDER_ERROR = '@orders/GET_ERROR';
export const GET_ORDER_PENDING = '@orders/GET_PENDING';

export const CREATE_ORDER_SUCCESS = '@orders/CREATE_SUCCESS';
export const CREATE_ORDER_ERROR = '@orders/CREATE_ERROR';
export const CREATE_ORDER_PENDING = '@orders/CREATE_PENDING';

export const CANCEL_ORDER_SUCCESS = '@orders/CANCEL_SUCCESS';
export const CANCEL_ORDER_ERROR = '@orders/CANCEL_ERROR';
export const CANCEL_ORDER_PENDING = '@orders/CANCEL_PENDING';

////////////////////////////////////////////////////
//  Action Creators
////////////////////////////////////////////////////

export function getAllOrdersPending(service) {
    return {
        type: GET_ALL_ORDERS_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: service },
    }
}

export function getAllOrdersSuccess(service, orders) {
    return {
        type: GET_ALL_ORDERS_SUCCESS,
        payload: orders,
        meta: { timestamp: Date.now(), target: service },
    }
}

export function getAllOrdersError(service, e, hidden = false) {
    return {
        type: GET_ALL_ORDERS_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: service },
    }
}

export function getOrderPending(id) {
    return {
        type: GET_ORDER_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: id },
    }
}

export function getOrderSuccess(order) {
    return {
        type: GET_ORDER_SUCCESS,
        payload: order,
        meta: { timestamp: Date.now(), target: order._id },
    }
}

export function getOrderError(id, e, hidden = false) {
    return {
        type: GET_ORDER_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: id },
    }
}

export function createOrderPending(slug) {
    return {
        type: CREATE_ORDER_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: slug },
    }
}

export function createOrderSuccess(slug, order) {
    return {
        type: CREATE_ORDER_SUCCESS,
        payload: order,
        meta: { timestamp: Date.now(), target: slug },
    }
}

export function createOrderError(slug, e, hidden = false) {
    return {
        type: CREATE_ORDER_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: slug },
    }
}

export function cancelOrderPending(id) {
    return {
        type: CANCEL_ORDER_PENDING,
        payload: {},
        meta: { timestamp: Date.now(), target: id },
    }
}

export function cancelOrderSuccess(order) {
    return {
        type: CANCEL_ORDER_SUCCESS,
        payload: order,
        meta: { timestamp: Date.now(), target: order._id },
    }
}

export function cancelOrderError(id, e, hidden = false) {
    return {
        type: CANCEL_ORDER_ERROR,
        payload: { error: e, hidden },
        meta: { timestamp: Date.now(), target: id },
    }
}

/**
 * Fetch all the services available for the user.
 * Populate the services with their infos automatically.
 */
export function getAllOrdersForService(service, user) {

    return (dispatch, getState) => {

        const pending = isGetAllOrdersByServicePending(getState(), service);

        if (!pending) {

            dispatch(getAllOrdersPending(service));

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: '/orders/',
                params: {
                    service,
                },
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {

                const orders = res.data.data;
                dispatch(getAllOrdersSuccess(service, orders));

                _.each(orders, (order) => {
                    dispatch(getOrderDetails(order.id, user));
                });

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getAllOrdersError(service, e, flag));
            });
        }
    }

}

export function getOrderDetails(order, user) {
    return (dispatch, getState) => {

        const pending = isGetOrderPending(getState(), order);
        const existing = isOrderPresent(getState(), order);

        if (!pending && !existing) {

            dispatch(getOrderPending(order));

            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/orders/${order}`,
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {

                const order = res.data.data;
                dispatch(getOrderSuccess(order));

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(getOrderError(order, e, flag));
            });

        }
    }
}

export function createOrder(device, plan, user) {
    return (dispatch, getState) => {

        const slug = makeOrderSlug(device, plan);
        const pending = isCreateOrderPending(getState(), slug);

        if (!pending) {

            dispatch(createOrderPending(slug));

            axios({
                method: 'put',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/orders/`,
                data: {
                    device,
                    plan,
                },
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {

                const order = res.data.data;
                dispatch(createOrderSuccess(slug, order));

                // Reload the associated license as it has been updated.
                const exists = getState().devices.byId[device];
                if (exists) {
                    dispatch(getLicenseDetails(exists._license, user, true));
                }

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(createOrderError(slug, e, flag));
            });

        }
    }
}

export function cancelOrder(id, user, deviceID) {
    return (dispatch, getState) => {

        const pending = isCancelOrderPending(getState(), id);

        if (!pending) {

            dispatch(cancelOrderPending(id));

            axios({
                method: 'post',
                baseURL: process.env.REACT_APP_API_HOST,
                url: `/orders/${id}/cancel`,
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            }).then(res => {

                const order = res.data.data;
                dispatch(cancelOrderSuccess(order));

                // Reload the associated license as it has been updated.
                if (deviceID) {
                    const exists = getState().devices.byId[deviceID];
                    if (exists) {
                        dispatch(getLicenseDetails(exists._license, user, true));
                    }
                }

            }).catch(e => {
                const flag = catchNetworkError(e, dispatch) || catchUnknownServerError(e, dispatch);
                dispatch(cancelOrderError(id, e, flag));
            });

        }
    }
}

////////////////////////////////////////////////////
//  Tools
////////////////////////////////////////////////////

function makeOrderSlug(deviceID, planID) {
    return `device:${deviceID}-plan:${planID}`;
}

////////////////////////////////////////////////////
//  Status check
////////////////////////////////////////////////////

export function isGetOrderPending(state, id) {
    return !!state.orders.isFetching[id];
}

export function isOrderPresent(state, id) {
    return !!state.orders.byId[id];
}

export function isGetAllOrdersByServicePending(state, service) {
    return !!state.orders.isFetchingAllByService[service];
}

export function isCreateOrderPending(state, slug) {
    return !!state.orders.isCreating[slug];
}

export function isCreateOrderErred(state, slug) {
    const status = state.devices.isCreating[slug];
    return _.isObjectLike(status); // Its erred when not a timestamp and not false
}

export function isCancelOrderPending(state, id) {
    return !!state.orders.isCancelling[id];
}

export function isCancelOrderErred(state, id) {
    const status = state.devices.isCancelling[id];
    return !_.isNumber(status) && status !== false; // Its erred when not a timestamp and not false
}

export function areOrdersLoading(state) {
    return _.find(state.orders.isFetching, (isFetching) => {
        return _.isNumber(isFetching); // error or false
    });
}