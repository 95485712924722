import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import {
    Button, Grid, ExpansionPanelDetails, ExpansionPanelActions, Divider,
} from '@material-ui/core';

import { Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import UserInfosController from './controller';

const styles = theme => ({
    form: {
        width: '100%',
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
    group: {
        marginBottom: theme.spacing.unit * 2,
    },
    helper: {

    },
});


class UserEditPanelForm extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <UserInfosController render={({ errors, isSubmitting }) => {
                return (
                    <Form className={classes.form}>
                        <ExpansionPanelDetails>
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <Field fullWidth label="Full Name" name="name" component={TextField} disabled={isSubmitting} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field fullWidth label="email" name="email" component={TextField} disabled={isSubmitting} />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                            <Button type="reset" size="small" disabled={isSubmitting}>Cancel</Button>
                            <Button type="submit" size="small" color="primary" disabled={isSubmitting}>
                                Save
                            </Button>
                        </ExpansionPanelActions>
                    </Form>
                )
            }} />)
    }
}

export default withStyles(styles)(UserEditPanelForm);