import React, { createElement } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Step, Stepper, StepLabel } from '@material-ui/core';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import step0 from './steps/step0';
import step1 from './steps/step1';
import step2 from './steps/step2';
import step3 from './steps/step3';
import step4 from './steps/step4';

import {
    /*getAllDevices, checkDeviceExists, createDevice, createOrder, cancelOrder,
    isCreateDevicePending, isCreateDeviceErred,
    isCheckDeviceExistsPending, isCheckDeviceExistsErred,
    isGetAllDevicesPending, isGetAllDevicesErred,
    isCreateOrderPending, isCreateOrderErred,
    isCancelOrderPending, isCancelOrderErred,*/
} from '../../../../redux/actions';


const styles = theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing.unit,
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    content: {
        flexGrow: 1,
        textAlign: 'center',
        padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 5}px`,
    },
});

class ActiveProStepper extends React.Component {

    static propTypes = {
        classes: PropTypes.object,
        service: PropTypes.object.isRequired,
        plans: PropTypes.array.isRequired,
        user: PropTypes.object.isRequired,
    }

    state = {
        isDisabled: false,
        current: 0,
        allSteps: [{
            name: 'Start',
            completed: false,
            result: null,
            error: null,
        }, {
            name: 'Device',
            completed: false,
            result: null,
            error: null,
        }, {
            name: 'Pricing Plan',
            completed: false,
            result: null,
            error: null,
        }, {
            name: 'Review',
            completed: false,
            result: null,
            error: null,
        }, {
            name: 'Good Bye',
            hidden: true,
            completed: false,
            result: null,
            error: null,
        }],
    }

    constructor(props) {
        super(props);

        this.save = this.save.bind(this);
        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
        this.operationPending = this.operationPending.bind(this);
        this.operationFinished = this.operationFinished.bind(this);
    }

    operationPending() {
        this.setState({ isDisabled: true });
        this.props.setSubmitting(true);
    }

    operationFinished() {
        this.setState({ isDisabled: false });
        this.props.setSubmitting(false);
    }

    save(result) {
        let { current, allSteps } = this.state;
        allSteps[current].result = result;
        this.setState({ allSteps });
    }

    next() {
        let { current, allSteps } = this.state;
        allSteps[current].completed = true;
        if (++current <= allSteps.length) { // Allow for up to length value for exit screen
            this.setState({ current, allSteps });
        }
    }

    previous() {
        let { current, allSteps } = this.state;
        if (--current >= 0) {
            allSteps[current].completed = false; // reset the completed state of the previous step
            this.setState({ current, allSteps });
        }
    }

    generateStep() {
        const { current, allSteps, isDisabled } = this.state;

        let elem = null;
        let props = {
            previous: this.previous,
            next: this.next,
            save: this.save,
            isDisabled,
            operationPending: this.operationPending,
            operationFinished: this.operationFinished,
            
            operation: allSteps[0].result,
            winid: allSteps[1].result,
            planid: allSteps[2].result,
            
            ...this.props
        };
        delete props.classes;



        switch (current) {
            case 0:
                elem = step0;
                break;
            case 1:
                elem = step1;
                break;
            case 2:
                elem = step2;
                break;
            case 3:
                elem = step3;
                break;
            case 4:
                elem = step4;
                break;
        }

        return createElement(elem, props);
    }

    render() {
        const { classes, service } = this.props;
        const { current, allSteps } = this.state;

        return (
            <div className={classes.root}>
                <Stepper activeStep={current}>
                    {allSteps.map((step, index) => {
                        if (step.hidden) return null;
                        return (
                            <Step key={index} completed={step.completed}>
                                <StepLabel>{step.name}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {this.generateStep()}
            </div>
        );
    }
}

const mapStateToProps = state => {

    const devices = _.mapValues(state.devices.byWinId, id => state.devices.byId[id]);
    let company = _.get(state, 'companies.owned');
    company = company && state.companies.byId[company];

    return {
        user: state.users.authenticated,
        company,
        devices: devices,
        licenses: state.licenses.byId,
        orders: state.orders.byId,
        // plans: are propagated from the top level component
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(ActiveProStepper));