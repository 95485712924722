import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';

import {
    createDevice, createOrder, cancelOrder,
    isCreateDevicePending, isCreateDeviceErred,
    isCreateOrderPending, isCreateOrderErred,
} from '../../../../../redux/actions';

import DefaultReview from './step3.default';
import RenewSubscriptionReview from './step3.cancel';

const styles = theme => ({
    root: {
        marginTop: `${theme.spacing.unit * 3}px`
    },
    explaination: {
        textAlign: 'center',
    },
});

class Review extends React.Component {

    state = {
        order: null,
    }

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        const { devices, winid } = this.props;
        const device = devices[winid] || { _win_id: winid };
        const order = this.findExistingOrder(device) || null;
        this.setState({ order });
    }

    findExistingOrder(device) {
        const { licenses, orders, service } = this.props;

        if (!device) return null;

        const license = licenses[device._license];
        const registeredOrders = license ?
            _.compact(license.orders.map(order => orders[order]))
            : [];

        return _.find(registeredOrders, (order) => order.service_model === service._id);
    }

    renderAlreadyOrdered(device, plan) {
        const { classes, previous, onClose } = this.props;

        return (
            <div className={classes.root}>

                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography className={classes.explaination}>
                            The Workplace {device._win_id} is already subscribed for this service !
                        </Typography>
                        <Typography className={classes.explaination}>
                            To change of plan, please unsubscribe the device first.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Button variant="contained" onClick={previous} color="secondary" fullWidth>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="outlined" onClick={onClose} color="secondary" fullWidth>
                            Ok, close.
                        </Button>
                    </Grid>
                </Grid>
            </div >
        )
    }


    render() {
        const { plans, devices, planid, winid } = this.props;
        const { order } = this.state;

        const device = devices[winid] || { _win_id: winid };
        const plan = _.find(plans, (plan) => plan._id === planid);

        const props = { ...this.props, plan, device, order };

        if (order) {
            // FIXME now ask the user the unsubscribe first.
            //if (order.plan_model === plan._id) {
            return this.renderAlreadyOrdered(device, plan);
            /*} else {
                return (<RenewSubscriptionReview {...props} />)
            }*/
        } else {
            return (<DefaultReview {...props} />)
        }
    }

}

const mapStateToProps = (state, ownProps) => {

    const winid = ownProps.winid || '';
    const device = ownProps.devices[winid] || {};
    const slug = `device:${device._id}-plan:${ownProps.planid}`;

    console.log(slug);

    return {
        isCreateDevicePending: isCreateDevicePending(state, winid),
        isCreateDevicesErred: isCreateDeviceErred(state, winid),
        isCreateOrderPending: isCreateOrderPending(state, slug),
        isCreateOrderErred: isCreateOrderErred(state, slug),
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        createDevice,
        createOrder,
        cancelOrder,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Review));
