import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ServicesOverview } from '../../items/service';
import { UserDetails } from '../../items/user';
import { Loader } from '../../items/misc';

import { flagWorkbenchStarts, flagWorkbenchStops, startLogin, pushInfo } from '../../../redux/actions';

const styles = theme => ({
    root: {
        width: '100%',
    },
    content: {
        flexGrow: 1,
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    },
    toolbar: theme.mixins.toolbar,
});

class Workbench extends React.Component {

    state = {
        shown: false,
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    }

    ////////////////////////////////////////////////////
    //  Constructors
    ////////////////////////////////////////////////////

    constructor(props) {
        super(props);
    }

    ////////////////////////////////////////////////////
    //  Life-Cycle
    ////////////////////////////////////////////////////

    componentWillMount() {
        const { user, startLogin, flagWorkbenchStarts, location } = this.props;
        if (user.expiresAt === null) {
            startLogin(location);
        }
        flagWorkbenchStarts(); // start the automated checks when the workbench starts
    }

    componentDidUpdate() {
        const { company, pushInfo } = this.props;
        const { shown } = this.state;
        /*if (company && !shown) {
            let message;
            if (company.payment.trial_available) {
                message = `You have 1 free license remaining`;
            } else {
                message = `Welcome ♥`;
            }
            pushInfo(message, { persist: true, action: 'OK' });
            this.setState({ shown: true });
        }*/
    }

    componentWillUnmount() {
        this.props.flagWorkbenchStops();
    }

    ////////////////////////////////////////////////////
    //  Render
    ////////////////////////////////////////////////////

    renderWorkbench() {
        const { classes, match } = this.props;

        return (

            <div className={classes.root}>
                <div className={classes.toolbar}></div>

                <div className={classes.content}>

                    <Switch>
                        <Route path={`${match.url}/profile`} component={UserDetails}></Route>
                        <Route path={`${match.url}/`} component={ServicesOverview}></Route>
                    </Switch>

                </div>

            </div>
        );
    }

    render() {
        const { classes, workbench } = this.props;

        return (
            <div className={classes.root}>
                {workbench === 'started' && this.renderWorkbench()}
                {workbench === 'starting' && <Loader message={'Wait while the console is starting ... '} />}
            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.users.authenticated,
    workbench: state.application.workbench,
    company: state.companies.byId[state.companies.owned],
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        flagWorkbenchStarts,
        flagWorkbenchStops,
        startLogin,
        pushInfo,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withStyles(styles)(Workbench)));
