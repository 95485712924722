import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Formik, } from 'formik';
import * as Yup from 'yup';

import { updateUserProfile, isUpdateUserProfilePending, isUpdateUserProfileErred } from '../../../../redux/actions';

const userSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Please provide a long enough name (3 characters)').required('Required'),
    email: Yup.string().email('Provide a valid email').required('Required'),
});


class UserEditController extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout);
    }

    waitForSave = (setSubmitting) => {
        this.timeout = setTimeout(() => {
            const { isUpdatePending, isUpdateErred } = this.props;

            if (!isUpdatePending || isUpdateErred) {
                setSubmitting(false);
                this.timeout = null;
            } else {
                this.waitForSave(setSubmitting);
            }

        }, 500);
    }

    render() {
        const { user, profile, updateUserProfile, render } = this.props;

        const initialValues = {
            name: profile.user_metadata.username || profile.nickname,
            email: profile.email,
        };

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={userSchema}
                onSubmit={(values, { setSubmitting }) => {
                    updateUserProfile(user, values);
                    this.waitForSave(setSubmitting);
                }}
                render={render} />)
    }
}

/**
 * Map the consents of the authenticated user
 */
const mapStateToProps = state => {
    const user = state.users.authenticated;
    return {
        user,
        profile: state.users.byProviderId[user.provider_id],
        isUpdatePending: isUpdateUserProfilePending(state, user),
        isUpdateErred: isUpdateUserProfileErred(state, user),
    }
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        updateUserProfile
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(UserEditController);