import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import UserConsentsForm from '../../../items/user/user-consents-form/default-form';

const styles = theme => ({
    
});


function intro(props) {

    const { classes } = props;

    return (
        <div>
            <Typography>
                Welcome on Active Licensing
            </Typography>
            <UserConsentsForm/>
        </div >
    )
}

export default withStyles(styles)(intro);
