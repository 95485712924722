import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        marginTop: `${theme.spacing.unit * 3}px`
    },
    explaination: {
        textAlign: 'center',
    },
});

class goodBye extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography className={classes.explaination}>
                            Your order is completed, you can find it in your dashboard and you will receive an email soon.
                            <br/>
                            Thank you.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={this.props.onClose} color="primary" fullWidth>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </div >
        )
    }

}

export default withStyles(styles)(goodBye);
