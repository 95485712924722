
import { combineReducers } from 'redux';
import _ from 'lodash';

import {
    NOTIF_SHOW, NOTIF_HIDE, NOTIF_CLOSE, NOTIF_SHOWN, NOTIF_DISMISSED,
} from '../actions';


function all(state = [], { type, payload, meta }) {

    state = _.clone(state);
    let index = -1;

    switch (type) {
        case NOTIF_SHOW:
            if (!_.find(state, (n) => n.key === meta.timestamp)) {
                state.push({ ...payload, show: true });
            }
            return state;
        case NOTIF_HIDE:
        case NOTIF_SHOWN:
            index = _.findIndex(state, (n) => n.key === payload.key)
            if (index >= 0) {
                state[index].show = false;
            }
            return state;
        case NOTIF_CLOSE:
        case NOTIF_DISMISSED:
            index = _.findIndex(state, (n) => n.key === payload.key)
            if (index >= 0) {
                state.splice(index, 1);
            }
            return state;
        default:
            return state;
    }

}

export default combineReducers({
    all,
});
