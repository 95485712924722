import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, MenuItem, FormHelperText } from '@material-ui/core';

import { Formik, Field, Form } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';

import { createUserOwnedCompany, isCreateCompanyErred, isCreateCompanyPending } from '../../../redux/actions';

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    form: {
        width: '100%',
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
    group: {
        marginBottom: theme.spacing.unit * 2,
    },
    helper: {

    },
});


const getCountry = (countries, selected) => {
    if (selected && selected >= 0 && selected < countries.length) {
        return countries[selected];
    }
    return undefined;
}

const getCounties = (countries, selected) => {
    const country = getCountry(countries, selected);
    if (country) return country.regions;
    return [];
}

const getCounty = (counties, selected) => {
    if (selected && selected >= 0 && selected < counties.length) {
        return counties[selected];
    }
    return undefined;
}

const CompanySchema = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    address: Yup.object().shape({
        street: Yup.string().required('Required'),
        street_opt: Yup.string(),
        city: Yup.string().required('Required'),
        post_code: Yup.string().required('Required'),
        country: Yup.number().min(0, 'Required'),
        county: Yup.number(),
    })
});

class CompanyEditForm extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout);
    }

    waitForCreation = (setSubmitting) => {
        this.timeout = setTimeout(() => {
            const { isCreatePending, isCreateErred } = this.props;

            if (!isCreatePending || isCreateErred) {
                setSubmitting(false);
                this.timeout = null;
            } else {
                this.waitForCreation(setSubmitting);
            }

        }, 500);
    }

    render() {
    const { classes, createUserOwnedCompany, user, countries } = this.props;

    return (

            <Formik className={classes.form}
                initialValues={{
                    name: '',
                    address: {
                        street: '',
                        street_opt: '',
                        city: '',
                        post_code: '',
                        country: -1,
                        county: -1,
                    }
                }}
                validationSchema={CompanySchema}
                onSubmit={(values, { setSubmitting }) => {

                    values = _.cloneDeep(values);
                    // Refactor values 
                    let country = getCountry(countries, values.address.country);
                    let counties = getCounties(countries, values.address.country);
                    let county = getCounty(counties, values.address.county);

                    values.address.country = country ? country.countryName : undefined;
                    values.address.county = county ? county.name : undefined;

                    createUserOwnedCompany(user, values);
                    this.waitForCreation(setSubmitting);
                }}

                render={({ values, errors, isSubmitting }) => {

                    const counties = getCounties(countries, values.address.country);

                    return (
                        <Form>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Field name="name" component={TextField} placeholder="Name of the company" label="Name" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field name="address.street" component={TextField} placeholder="Street" label="Street" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field name="address.street_opt" component={TextField} placeholder="Street 2nd line" label="Street 2nd line" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field name="address.city" component={TextField} placeholder="City" label="City" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field name="address.post_code" component={TextField} placeholder="Post/Zip Code" label="Post/Zip Code" fullWidth />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field name="address.country" component={Select}
                                        placeholder="Country" label="Country" fullWidth>
                                        ({countries.map((country, index) => {
                                            return <MenuItem key={index} value={index}>{country.countryName}</MenuItem>;
                                        })})
                                    </Field>
                                    {errors.address && errors.address.country && <FormHelperText error>{errors.address.country}</FormHelperText>}
                                </Grid>

                                <Grid item xs={6}>
                                    <Field name="address.county" component={Select} placeholder="County" label="County" fullWidth>
                                        ({counties.map((county, index) => {
                                            return <MenuItem key={index} value={index}>{county.name}</MenuItem>;
                                        })})
                                    </Field>
                                    {errors.address && errors.address.county && <FormHelperText error>{errors.address.county}</FormHelperText>}
                                </Grid>

                                <Grid item xs={6}>
                                    <Button type="submit" variant="contained" disabled={isSubmitting} color="primary" fullWidth>
                                        Save the company
                                </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }} />
    )
};

}

/**
 * Map the consents of the authenticated user
 */
const mapStateToProps = state => {
    return {
        user: state.users.authenticated,
        countries: state.static.regions,
        isCreatePending: isCreateCompanyPending(state),
        isCreateErred: isCreateCompanyErred(state),
    }
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        createUserOwnedCompany
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(CompanyEditForm));
