import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';

import { Formik } from 'formik';
import {
    Elements, injectStripe
} from 'react-stripe-elements';

import { attachSource, catchStripeError, isAttachingSourcePending, isAttachingSourceErred } from '../../../../redux/actions';


class AddSourceFormController extends React.Component {

    state = {
        isSubmitting: false,
    }

    constructor(props) {
        super(props);
        this.setSubmitting = this.setSubmitting.bind(this);
    }

    setSubmitting(bool) {
        this.setState({ isSubmitting: bool });
    }

    componentDidUpdate() {
        const { isPending, isErred, onSuccess } = this.props;
        const { isSubmitting } = this.state;

        if (isSubmitting) {
            if (!isPending) {
                this.setSubmitting(false);
                if (!isErred) onSuccess();
            }
        }
    }

    handleStripeSubmit(stripe, setSubmitting) {
        const { user, company, attachSource, catchStripeError } = this.props;
        stripe
            .createSource({ type: 'card' })
            .then(({ source, error }) => {
                
                if (error) {
                    catchStripeError(error);
                } else {
                    attachSource(user, company, source);
                    this.setSubmitting(true);
                }

                setSubmitting(false);
            });
    }

    render() {
        const { render } = this.props;
        const { isSubmitting } = this.state;

        return (
            <Elements>
                {React.createElement(injectStripe(({ stripe }) => {

                    return (
                        <Formik
                            onSubmit={(values, { setSubmitting }) => {
                                this.handleStripeSubmit(stripe, setSubmitting);
                            }}
                            render={props => {
                                return React.createElement(render, { isSubmitting, ...props });
                            }} />
                    )
                }))}
            </Elements>
        )

    };

}

/**
 * Map the consents of the authenticated user
 */
const mapStateToProps = state => {
    const company = state.companies.byId[state.companies.owned];
    return {
        user: state.users.authenticated,
        company,
        isPending: isAttachingSourcePending(state, company),
        isErred: isAttachingSourceErred(state, company),
    }
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        attachSource,
        catchStripeError,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(AddSourceFormController);
