import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';

import { Divider, LinearProgress } from '@material-ui/core';
import { } from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


const styles = theme => ({
    divider: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 2,
    }
});

class CompanyDetails extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        dataProvider: PropTypes.object,
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            company: this.props.company || this.props.api.Company(this.props.match.params.id)
        };
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
        
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(CompanyDetails)));