import { combineReducers } from 'redux';
import _ from 'lodash';

import {
    GET_PAGE_SUCCESS,
    GET_ALL_PAGES_SUCCESS, GET_ALL_PAGES_ERROR, GET_ALL_PAGES_PENDING,
} from '../../actions';

function byId(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_PAGE_SUCCESS:
            _.merge(state, { [payload._id]: payload });
            return state;
        default:
            return state;
    }
}

function allIds(state = [], { type, payload, meta }) {
    state = _.clone(state);

    switch (type) {
        case GET_ALL_PAGES_SUCCESS:
            _.each(payload, (page) => {
                !state.includes(page) && state.push(page);
            });
            return state;
        default:
            return state;
    }
}

function isFetchingAll(state = false, { type, payload, meta }) {
    switch (type) {
        case GET_ALL_PAGES_SUCCESS:
            return false;
        case GET_ALL_PAGES_ERROR:
            return payload.error;
        case GET_ALL_PAGES_PENDING:
            return meta.timestamp;
        default:
            return state;
    }
}

export default combineReducers({
    byId,
    allIds,
    isFetchingAll,
});