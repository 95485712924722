import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';


import { CompanyCreateForm } from '../../../items/company';

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
});

function company(props) {

    const { classes } = props;

    return (
        <div>
            <Typography variant='h6' component='h4' align='left'>
                Information you provide us are only used for the delivery of the Active Licensing service.
            </Typography>
            <CompanyCreateForm />
        </div >
    )
}

export default withStyles(styles)(company);
