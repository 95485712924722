import { combineReducers } from 'redux';
import _ from 'lodash';

import {
    GET_USER_PROFILE_INFOS_SUCCESS, GET_USER_PROFILE_INFOS_PENDING, GET_USER_PROFILE_INFOS_ERROR,
    LOGIN_USER, LOGOUT_USER,
    UPDATE_USER_CONSENTS_SUCCESS, UPDATE_USER_CONSENTS_ERROR, UPDATE_USER_CONSENTS_PENDING,
    UPDATE_USER_PROFILE_SUCCESS, UPDATE_USER_PROFILE_ERROR, UPDATE_USER_PROFILE_PENDING,
} from '../actions';



function authenticated(state = {accessToken: null, provider_id: null}, { type, payload}) {
    switch (type) {
        case LOGIN_USER:
            return {accessToken: payload.jwt, provider_id: payload.profile.sub, expiresAt: payload.expiresAt};
        case LOGOUT_USER:
            return {accessToken: null, provider_id: null, expiresAt: null};
        default:
            return state;
    }
}

function byProviderId(state = {}, { type, payload }) {
    switch (type) {
        case GET_USER_PROFILE_INFOS_SUCCESS:
        case UPDATE_USER_CONSENTS_SUCCESS:
        case UPDATE_USER_PROFILE_SUCCESS:
            _.merge(state, { [payload.provider_id]: payload });
            return state;
        default:
            return state;
    }
}

function isFetchingProfile(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_USER_PROFILE_INFOS_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case GET_USER_PROFILE_INFOS_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case GET_USER_PROFILE_INFOS_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isUpdatingProfile(state = {}, { type, payload, meta }) {
    switch (type) {
        case UPDATE_USER_PROFILE_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case UPDATE_USER_PROFILE_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case UPDATE_USER_PROFILE_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isUpdatingConsents(state = {}, { type, payload, meta }) {
    switch (type) {
        case UPDATE_USER_CONSENTS_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case UPDATE_USER_CONSENTS_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case UPDATE_USER_CONSENTS_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}


export default combineReducers({
    authenticated,
    byProviderId,
    isUpdatingConsents,
    isFetchingProfile,
    isUpdatingProfile,
});
