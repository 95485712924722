import { combineReducers } from 'redux';
import _ from 'lodash';

import {
    GET_ORDER_SUCCESS, GET_ORDER_PENDING, GET_ORDER_ERROR,
    GET_ALL_ORDERS_SUCCESS, GET_ALL_ORDERS_PENDING, GET_ALL_ORDERS_ERROR,
    CREATE_ORDER_SUCCESS, CREATE_ORDER_PENDING, CREATE_ORDER_ERROR,
    CANCEL_ORDER_SUCCESS, CANCEL_ORDER_PENDING, CANCEL_ORDER_ERROR,
} from '../actions';

function byService(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_ALL_ORDERS_SUCCESS:
            {
                // Merge orders list
                let orders = state[meta.target] || [];
                orders = _.clone(orders);
                _.each(payload, (order) => {
                    !orders.includes(order.id) && orders.push(order.id);
                });
                // Merge state
                _.merge(state, { [meta.target]: orders });
                return state;
            }

        case GET_ORDER_SUCCESS:
            {
                // Merge orders list
                let orders = state[payload.service_model] || [];
                orders = _.clone(orders);
                !orders.includes(payload._id) && orders.push(payload._id);
                // Merge state
                _.merge(state, { [payload.service_model]: orders });
                return state;

            }

        default:
            return state;
    }
}

function byId(state = {}, { type, payload }) {

    switch (type) {
        case GET_ORDER_SUCCESS:
        case CREATE_ORDER_SUCCESS:
            _.merge(state, { [payload._id]: payload });
            return state;
        default:
            return state;
    }

}

function allIds(state = [], { type, payload }) {

    state = _.clone(state);

    switch (type) {
        case GET_ALL_ORDERS_SUCCESS:
            _.each(payload, (order) => {
                !state.includes(order.id) && state.push(order.id);
            });
            return state;
        case CREATE_ORDER_SUCCESS:
            !state.includes(payload._id) && state.push(payload._id);
            return state;
        default:
            return state;
    }
}

function isFetching(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_ORDER_SUCCESS:
            return _.merge(state, { [payload._id]: false });
        case GET_ORDER_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case GET_ORDER_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isCreating(state = {}, { type, payload, meta }) {
    switch (type) {
        case CREATE_ORDER_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case CREATE_ORDER_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case CREATE_ORDER_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isCancelling(state = {}, { type, payload, meta }) {
    switch (type) {
        case CANCEL_ORDER_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case CANCEL_ORDER_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case CANCEL_ORDER_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isFetchingAllByService(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_ALL_ORDERS_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case GET_ALL_ORDERS_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case GET_ALL_ORDERS_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

export default combineReducers({
    byId,
    byService,
    allIds,
    isCreating,
    isCancelling,
    isFetching,
    isFetchingAllByService,
});
