import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import {
    Grid, Button, MenuItem, FormHelperText,
    ExpansionPanelDetails, ExpansionPanelActions, Divider,
} from '@material-ui/core';

import { Field, Form } from 'formik';
import { TextField, Select } from 'formik-material-ui';

import CompanyEditFormController from './controller';

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    form: {
        width: '100%',
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
});

class CompanyEditForm extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <CompanyEditFormController
                render={({ values, errors, isSubmitting, countries, getCountry, getCounties, getCounty }) => {

                    const counties = getCounties(countries, values.address.country);

                    return (
                        <Form className={classes.form}>
                            <ExpansionPanelDetails>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Field name="name" component={TextField} placeholder="Name of the company" label="Name" fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field name="address.street" component={TextField} placeholder="Street" label="Street" fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field name="address.street_opt" component={TextField} placeholder="Street 2nd line" label="Street 2nd line" fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field name="address.city" component={TextField} placeholder="City" label="City" fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field name="address.post_code" component={TextField} placeholder="Post/Zip Code" label="Post/Zip Code" fullWidth />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field name="address.country" component={Select}
                                            placeholder="Country" label="Country" fullWidth>
                                            ({countries.map((country, index) => {
                                                return <MenuItem key={index} value={index}>{country.countryName}</MenuItem>;
                                            })})
                                    </Field>
                                        {errors.address && errors.address.country && <FormHelperText error>{errors.address.country}</FormHelperText>}
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field name="address.county" component={Select} placeholder="County" label="County" fullWidth>
                                            ({counties.map((county, index) => {
                                                return <MenuItem key={index} value={index}>{county.name}</MenuItem>;
                                            })})
                                    </Field>
                                        {errors.address && errors.address.county && <FormHelperText error>{errors.address.county}</FormHelperText>}
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                            <Divider />
                            <ExpansionPanelActions>
                                <Button type="reset" size="small" disabled={isSubmitting}>Cancel</Button>
                                <Button type="submit" size="small" color="primary" disabled={isSubmitting}>
                                    Save
                                </Button>
                            </ExpansionPanelActions>

                        </Form>
                    )
                }} />
        )
    };

}

export default withStyles(styles)(CompanyEditForm);
