import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import {
    Drawer, List, ListItem, ListItemIcon,
    ListItemText, Divider, Collapse,
} from '@material-ui/core';

import {
    Dashboard as DashboardIcon,
    AccountCircleOutlined as ProfileIcon,
    StarBorder as ServiceIcon,
    Lock as PrivacyIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

const drawerWidth = 240;

const styles = theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});

class Sidebar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { classes, push, services, pages } = this.props;

        return (
            <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }}>
                <div className={classes.toolbar} />
                <List>
                    <ListItem button onClick={() => push('/workbench')}>
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary='Dashboard' />
                    </ListItem >
                </List>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemIcon><ServiceIcon /></ListItemIcon>
                        <ListItemText primary='Services' />
                    </ListItem >
                    <Collapse in timeout="auto" unmountOnExit>
                        <List dense component="div" disablePadding>
                            {services.map((service, index) => {
                                return (
                                    <ListItem key={index} button className={classes.nested}
                                        onClick={() => push(`/workbench/services/${service._id}`)}>
                                        <ListItemText
                                            primary={service.description.title || service.name}/>
                                    </ListItem >
                                )
                            })}
                        </List>
                    </Collapse>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={() => push('/workbench/profile')}>
                        <ListItemIcon><ProfileIcon /></ListItemIcon>
                        <ListItemText primary='My Profile' />
                    </ListItem >
                </List>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemIcon><PrivacyIcon /></ListItemIcon>
                        <ListItemText primary='Legal' />
                    </ListItem >
                    <Collapse in timeout="auto" unmountOnExit>
                        <List dense component="div" disablePadding>
                            {pages.map((page, index) => (
                                <ListItem key={index} button className={classes.nested}
                                    onClick={() => push(`/static${page.parameters.uri}`)}>
                                    <ListItemText
                                        primary={page.description.title}/>
                                </ListItem >
                            ))}
                        </List>
                    </Collapse>

                </List>
            </Drawer>);
    }
}

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    services: _.map(state.services.byId),
    pages: _.map(state.static.pages.byId),
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        push
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withStyles(styles)(Sidebar)));


