import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

// import all reducers
import { default as application } from './application';
import { default as companies } from './companies';
import { default as devices } from './devices';
import { default as licenses } from './licenses';
import { default as notifications } from './notifications';
import { default as onboarding } from './on-boarding';
import { default as orders } from './orders';
import { default as plans } from './plans';
import { default as services } from './services';
import { default as staticReducer } from './static/index';
import { default as users } from './users';

// Factory function
export default (history) => combineReducers({
    application,
    onboarding,
    
    companies,
    devices,
    licenses,
    orders,
    plans,
    services,
    users,

    static: staticReducer,
    router: connectRouter(history),
    notifications,
});