import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import {
    Card, CardMedia, CardActions, CardContent,
    Typography, IconButton
} from '@material-ui/core';

import {
    Add as AddIcon,
} from '@material-ui/icons';

import NewOrderDialog from '../order/new-order-dialog';
import { getAllOrdersForService } from '../../../redux/actions';
import background from '../../../assets/images/illustration-b@2x.png';

const styles = theme => ({
    root: {
        margin: `-${theme.spacing.unit * 2}px -${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px -${theme.spacing.unit * 3}px`,
    },
    card: {
        borderRadius: 0,
        position: 'relative',
    },
    media: {
        backgroundColor: theme.palette.primary[theme.palette.type],
        backgroundPosition: 'top',
        minHeight: 340,
    },
    content: {
        position: 'absolute',
        left: 0,
        bottom: `${theme.spacing.unit * 4}px`,
        padding: `${theme.spacing.unit * 4}px`,
    },
    overlayTypo: {
        color: theme.palette.common.white,
    },
    title: {
        marginBottom: `${theme.spacing.unit * 2}px`,
    },
    heading: {
        width: '60%',
    },
    actions: {
        position: 'absolute',
        bottom: `${theme.spacing.unit * 2}px`,
        left: `${theme.spacing.unit * 3}px`,
    },
    icon: {
        color: theme.palette.primary[theme.palette.type],
        backgroundColor: theme.palette.common.white,
        border: `solid ${theme.palette.common.white} 2px`,
    }
});

class ServiceHeader extends React.Component {

    state = {
        open: false,
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    constructor (props) {
        super(props);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    openDialog() {
        this.setState({ open: true });
    }

    closeDialog() {
        const { service, user, getAllOrdersForService } = this.props;
        getAllOrdersForService(service._id, user);
        this.setState({ open: false });
    }

    render() {
        const { classes, service, plans, children } = this.props;

        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.media}
                        image={(service && service.description.image) || background}
                        title={(service && (service.description.title || service.name)) || 'service logo'}
                    />
                    <CardContent className={classes.content}>
                        <Typography className={classnames(classes.overlayTypo, classes.title)} gutterBottom variant="h4" component="h2">
                            {(service && (service.description.title || service.name)) || null}
                        </Typography>
                        <Typography className={classnames(classes.overlayTypo, classes.heading)} variant="h6" component="h2">
                            {(service && (service.description.description)) || null}
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <IconButton className={classes.icon} onClick={this.openDialog}>
                            <AddIcon />
                        </IconButton>
                    </CardActions>
                    {children}
                </Card>
                <NewOrderDialog open={this.state.open} service={service} plans={plans} handleClose={this.closeDialog} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    let plans = [];
    // Get the service from the list passed as props.
    let service = ownProps.service;

    if (!service) {
        console.error('Service is undefined, error loading service');
    } else {
        plans = _.map(service.plans, (plan) => state.plans.byId[plan]);
        plans = _.compact(plans);
    }

    return {
        user: state.users.authenticated,
        plans,
    };
}



const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        getAllOrdersForService,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(ServiceHeader));
