import { combineReducers } from 'redux';
import _ from 'lodash';

import {
    GET_PLAN_SUCCESS, GET_PLAN_PENDING, GET_PLAN_ERROR, 
    GET_ALL_PLANS_SUCCESS, GET_ALL_PLANS_PENDING, GET_ALL_PLANS_ERROR,
} from '../actions';

function byName(state = {}, { type, payload }) {

    switch (type) {
        case GET_PLAN_SUCCESS:
            _.merge(state, { [payload.name]: payload._id });
            return state;
        default:
            return state;
    }

}

function byService(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_ALL_PLANS_SUCCESS:
            {
                // Merge orders list
                let plans = state[meta.target] || [];
                plans = _.clone(plans);
                _.each(payload, (plan) => {
                    !plans.includes(plan.id) && plans.push(plan.id);
                });
                // Merge state
                _.merge(state, { [meta.target]: plans });
                return state;
            }

        case GET_PLAN_SUCCESS:
            {
                // Merge orders list
                let plans = state[payload.service_id] || [];
                plans = _.clone(plans);
                !plans.includes(payload._id) && plans.push(payload._id);
                // Merge state
                _.merge(state, { [payload.service_id]: plans });
                return state;

            }

        default:
            return state;
    }
}


function byId(state = {}, { type, payload }) {

    switch (type) {
        case GET_PLAN_SUCCESS:
            _.merge(state, { [payload._id]: payload });
            return state;
        default:
            return state;
    }

}

function allIds(state = [], { type, payload }) {

    state = _.clone(state);

    switch (type) {
        case GET_ALL_PLANS_SUCCESS:
            _.each(payload, (plan) => {
                !state.includes(plan.id) && state.push(plan.id);
            });
            return state;
        default:
            return state;
    }
}

function isFetching(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_PLAN_SUCCESS:
            return _.merge(state, { [payload._id]: false });
        case GET_PLAN_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case GET_PLAN_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}

function isFetchingAllByService(state = {}, { type, payload, meta }) {
    switch (type) {
        case GET_ALL_PLANS_SUCCESS:
            return _.merge(state, { [meta.target]: false });
        case GET_ALL_PLANS_ERROR:
            return _.merge(state, { [meta.target]: payload.error });
        case GET_ALL_PLANS_PENDING:
            return _.merge(state, { [meta.target]: meta.timestamp });
        default:
            return state;
    }
}


export default combineReducers({
    byId,
    byService,
    byName,
    allIds,
    isFetchingAllByService,
    isFetching,
});
