import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

import { replace } from 'connected-react-router';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CircularProgress, Typography, Button, Grid } from '@material-ui/core';

const styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        margin: 'auto',
    },
    spaced: {
        marginTop: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit * 2,
    },
});


class ErrorBoundary extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
    }

    state = {
        error: null,
        event: null,
        loading: false,
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    }

    componentDidCatch(error, errorInfo) {
        const { user, profile } = this.props;

        this.setState({ loading: true });

        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            scope.setUser({
                email: profile ? profile.email : undefined,
                id: user ? user.provider_id : undefined,
            });
            const event = Sentry.captureException(error);
            this.setState({ event });
            this.resetLoading();
        });
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        if (this.timemout) clearTimeout(this.timeout);
    }

    resetLoading = () => {
        this.timeout = setTimeout(() => {
            this.setState({ loading: false });
            this.timeout = null;
        }, 1000);
    }

    renderBoundary = () => {
        const { classes, replace } = this.props;
        const { event, loading } = this.state;

        return (
            <div className={classes.centered}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h5' component='h3'>Something went wrong ... We are sorry for the inconvenience.</Typography>
                        <Typography>Our team has received an automatic report of the issue and will work on it asap.</Typography>
                    </Grid>
                    <Grid className={classes.spaced} item xs={12}>
                        {loading ?
                            <CircularProgress />
                            :
                            <div>
                                <Button className={classes.button} variant='outlined' onClick={() => {
                                    this.setState({ error: null, event: null });
                                    replace('/');
                                }}>Go back</Button>
                                <Button className={classes.button} variant='contained' onClick={() => {
                                    Sentry.showReportDialog({ eventId: event });
                                }}>Help us by writing a report 🤗</Button>
                            </div>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }

    render() {
        const { classes, children } = this.props;
        const { error } = this.state;

        return (
            <div className={classes.root}>
                {error ? this.renderBoundary() : children}
            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.users.authenticated,
    profile: state.users.byProviderId[state.users.authenticated.provider_id]
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        replace,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(ErrorBoundary));