import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { Switch, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import {
    ExpansionPanel, ExpansionPanelSummary, Typography, Divider,
} from '@material-ui/core';

import {
    ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';

import UserHeader from './user-header';
import UserConsentsPanelForm from './user-consents-form/expansion-panel-form';
import UserEditPanelForm from './user-infos-form/expansion-panel-form';
import CompanyEditForm from '../company/company-edit-form/expansion-panel-form';
import EditSourceForm from '../billing/edit-source-form/expansion-panel-form';
import ChooseCurrencyForm from '../billing/choose-currency-form/expansion-panel-form';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    spaced: {
        marginTop: theme.spacing.unit * 2,
    }
});

class UserDetails extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    render() {
        const { classes, user, profile, } = this.props;
        return (
            <div>
                <UserHeader user={user} profile={profile} />
                <div className={classes.root}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6' component='h4' className={classes.heading}>Personal information</Typography>
                        </ExpansionPanelSummary>
                            <UserEditPanelForm/>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6' component='h4' className={classes.heading}>Privacy consents</Typography>
                        </ExpansionPanelSummary>
                            <UserConsentsPanelForm />
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6' component='h4' className={classes.heading}>Company's details</Typography>
                        </ExpansionPanelSummary>
                        <CompanyEditForm />
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6' component='h4' className={classes.heading}>Billing Information</Typography>
                        </ExpansionPanelSummary>
                        <EditSourceForm />

                        <Divider className={classes.spaced}/>
                        <ChooseCurrencyForm />
                    </ExpansionPanel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const user = state.users.authenticated;
    return {
        user,
        profile: state.users.byProviderId[user.provider_id],
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(UserDetails));
