import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import {
    Button, ExpansionPanelDetails, ExpansionPanelActions, Divider,
} from '@material-ui/core';

import SourceEditFormController from './controller';
import SourceTable from '../source-table';
import AddSourceDialog from '../add-source-form/dialog-form';

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    form: {
        width: '100%',
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
});

class SourceEditExpansionForm extends React.Component {

    state = {
        open: false,
    }

    handleAddClick = () => {
        this.setState({ open: true });
    }

    handleAddDialogClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { classes } = this.props;
        const { open } = this.state;

        return (
            <div>
                <SourceEditFormController
                    render={({ data, sources, isDeleteEnabled, handleDelete }) => {

                        return (
                            <div>
                                <ExpansionPanelDetails>
                                    <SourceTable
                                        data={data}
                                        isDeleteEnabled={isDeleteEnabled}
                                        handleDelete={handleDelete}
                                    />
                                </ExpansionPanelDetails>
                                <Divider />
                                <ExpansionPanelActions>
                                    <Button size="small" color="primary"
                                        onClick={this.handleAddClick}>
                                        Add
                                </Button>
                                </ExpansionPanelActions>
                            </div>
                        )
                    }} />
                <AddSourceDialog
                    open={open}
                    handleClose={this.handleAddDialogClose}
                />
            </div>


        )
    };

}

export default withStyles(styles)(SourceEditExpansionForm);
