import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogContent, DialogTitle,
    LinearProgress, Grid, Typography, Button
} from '@material-ui/core';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cancelOrder } from '../../../redux/actions';


const styles = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
});

class CancelOrderDialog extends React.Component {

    state = {
        isSubmitting: false,
    }

    static propTypes = {
        handleClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        order: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);
    }

    setSubmitting = (bool) => {
        this.setState({ isSubmitting: bool });
    }

    cancelOrder = () => {
        const { order, device, user, cancelOrder } = this.props;
        cancelOrder(order._id, user, device._id);
        this.waitForOrderCancelation(order._id);
    }

    waitForOrderCancelation = () => {
        const { order } = this.props;
        this.timeout = setTimeout(() => {
            const { isOrderCancelling, handleClose } = this.props
            const pending = isOrderCancelling[order._id];
            // strictly false
            if (pending === false) {
                this.timeout = null;
                this.setSubmitting(false);
                handleClose();
            } else if (_.isNumber(pending)) {
                this.waitForOrderCancelation();
            } else {
                this.setSubmitting(false);
            }
        }, 500);
    }

    render() {

        const { classes, service, device, open, handleClose } = this.props;
        const { isSubmitting } = this.state;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="cancel-order"
            >
                {isSubmitting && <LinearProgress />}
                <DialogTitle id="cancel-order">Cancel {service.description.title || service.name} for {device.name}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography className={classes.explaination}>
                                If you continue, the subscription will be canceled at the end of the period.
                            </Typography>
                            <Typography className={classes.explaination}>
                                This operation cannot be undone. No refund is available.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Button variant="contained" disabled={isSubmitting} onClick={handleClose} color="primary" fullWidth>
                                No
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined" disabled={isSubmitting} onClick={this.cancelOrder} color="secondary" fullWidth>
                                Yes, stop the subscription
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.users.authenticated,
        isOrderCancelling: state.orders.isCancelling,
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        cancelOrder,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(CancelOrderDialog));