import { push, replace } from 'connected-react-router'
import _ from 'lodash';

import {
    NEXT_ON_BOARDING_STEP, START_ON_BOARDING, UPDATE_USER_CONSENTS_SUCCESS,
    CREATE_OWNED_COMPANY_SUCCESS, UPDATE_COMPANY_SUCCESS, STOP_ON_BOARDING,
    nextStep, setCurrentStep,
    stopConsentsOnBoarding, stopCompanyOnBoarding, stopCurrencyOnBoarding,
} from '../actions';

let timeout = null;


function middlewareContext(ctx) {

    function step(state) {
        return isActivated(state) ? state.onboarding.allSteps[state.onboarding.current] : undefined;
    }

    function isActivated(state) {
        return state.onboarding.current !== -1;
    }

    return store => next => action => {

        // Make sure call this at the beginning, so that state
        // changes already happened when we have to redirect.
        const nextAction = next(action);
        const state = store.getState();

        // These actions are always being handled, even if the user
        // iss currently not being on-boarded.
        switch (action.type) {

            case STOP_ON_BOARDING: {
                store.dispatch(nextStep());
            }

            case START_ON_BOARDING:
                if (timeout) {
                    clearTimeout(timeout);
                }
                timeout = setTimeout(() => {
                    store.dispatch(nextStep()); // Activate the onboarding when all steps have loaded
                }, 1500);
                break;

            case NEXT_ON_BOARDING_STEP:
                const firstMissing = _.findIndex(state.onboarding.allSteps, (step) => step.missing);
                store.dispatch(setCurrentStep(firstMissing)); // deactivate the onboarding if required
                if (firstMissing >= 0) {
                    store.dispatch(push(state.onboarding.allSteps[firstMissing].location)); // Route to the missing step
                } else {
                    store.dispatch(replace('/'));
                }
                break;

            // When the on-boarding is activated on 'welcome' and the update user consent action was called
            case UPDATE_USER_CONSENTS_SUCCESS:
                if (isActivated(state) && step(state).name === 'welcome') {
                    store.dispatch(stopConsentsOnBoarding()); // mark the step as resolved
                }
                break;

            case CREATE_OWNED_COMPANY_SUCCESS:
                if (isActivated(state) && step(state).name === 'company') {
                    store.dispatch(stopCompanyOnBoarding()); // mark the step as resolved
                }
                break;

            case UPDATE_COMPANY_SUCCESS:
                if (isActivated(state) && step(state).name === 'currency') {
                    store.dispatch(stopCurrencyOnBoarding()); // mark the step as resolved
                }
                break;

            case '@@router/LOCATION_CHANGE':

                if (!isActivated(state) && action.payload.location.pathname.startsWith('/onboarding')) {
                    store.dispatch(replace('/')); // redirect to the home page if onboarding is not activated
                } else if (isActivated(state)
                    && action.payload.location.pathname !== step(state).location) {
                    const method = action.payload.location.pathname.startsWith('/onboarding') ? replace : push;
                    store.dispatch(method(step(state).location));
                }
                break;
        }

        return nextAction;

    };

}

export default middlewareContext;