// A Wrapper for the <FormControl>, <InputLabel>, <Error> and the Stripe <*Element>.
// Similar to Material UI's <TextField>. Handles focused, empty and error state
// to correctly show the floating label and error messages etc.

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TextField, withStyles } from '@material-ui/core'

import StripeInput from './stripe-input';


const styles = (theme) => ({
    textField: {
        margin: 0,
    },
})

class StripeElementWrapper extends Component {

    static propTypes = {
        component: PropTypes.func,
        componentProps: PropTypes.object,
        label: PropTypes.string.isRequired,
        classes: PropTypes.object.isRequired,
    }

    state = {
        focused: false,
        empty: true,
        error: false,
    }

    constructor(props) {
        super(props);

        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleBlur() {
        this.setState({ focused: false })
    }

    handleFocus() {
        this.setState({ focused: true })
    }

    handleChange(changeObj) {
        this.setState({
            error: changeObj.error,
            empty: changeObj.empty,
        })
    }

    render() {
        const { component, componentProps, label, name, classes} = this.props
        const { focused, empty, error } = this.state

        return (
            <div>
                <TextField
                    className={classes.textField}
                    variant='outlined'
                    fullWidth
                    margin="normal"
                    focused={focused.toString()}

                    error={!!error}
                    label={label}
                    name={name || label.toLowerCase()}

                    helperText={error ? error.message : undefined}

                    InputLabelProps={{ shrink: focused || !empty }}
                    InputProps={{
                        inputComponent: component ? StripeInput : undefined,
                        onFocus: this.handleFocus,
                        onBlur: this.handleBlur,
                        onChange: this.handleChange,
                    }}
                    inputProps={{ component, componentProps }}
                ></TextField>
            </div>
        )
    }
}

export default withStyles(styles)(StripeElementWrapper);