import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { updateUserConsents, isUpdateUserConsentsPending, isUpdateUserConsentsErred } from '../../../../redux/actions';

const userConsentsSchema = Yup.object().shape({
    'general-terms': Yup.boolean().oneOf([true], 'Required').required('Required'),
    'newsletter': Yup.boolean().required('Required'),
});


class UserConsentsFormController extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout);
    }

    waitForSave = (setSubmitting) => {
        this.timeout = setTimeout(() => {
            const { isUpdatePending, isUpdateErred } = this.props;

            if (!isUpdatePending || isUpdateErred) {
                setSubmitting(false);
                this.timeout = null;
            } else {
                this.waitForSave(setSubmitting);
            }

        }, 500);
    }

    render() {
        const { render, user, profile, updateUserConsents } = this.props;

        const initialValues = Object.assign({
            'general-terms': true,
            'newsletter': false,
        }, _.mapValues(_.keyBy(profile.user_metadata.consents || [], 'name'), 'accepted'));

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={userConsentsSchema}
                onSubmit={(values, { setSubmitting }) => {
                    updateUserConsents(user, values);
                    this.waitForSave(setSubmitting);
                }}
                render={render} />)
    }
}

/**
 * Map the consents of the authenticated user
 */
const mapStateToProps = state => {
    const user = state.users.authenticated;
    return {
        user,
        isUpdatePending: user && isUpdateUserConsentsPending(state, user),
        isUpdateErred: user && isUpdateUserConsentsErred(state, user),
        profile: state.users.byProviderId[user.provider_id],
    }
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        updateUserConsents
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(UserConsentsFormController);